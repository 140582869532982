import React, { useCallback, useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AppBar } from '@redislabsdev/redislabs-ui-components';
import StyledToastContainer, { showToast } from '../../components/Toast/Toast';
import { api, buildUrl, fileApi } from '../../api/config';
import RoutesPage from '../RoutesPage';
import {
  AUDILOGS_READ,
  CONTRACTS_READ,
  MARKETPLACE_CONTRACT_READ,
  MANUAL_A_A_READ,
  POCS_READ,
  COUPONS_READ,
  ACCOUNTS_MARKETPLACES_READ,
  MAINTENANCE_WINDOW_READ,
} from '../../constants/permissionsConstants';
import { StoreInterface } from '../../interfaces/storeInterfaces';
import MainSideBar from '../../components/MainSideBar/MainSideBar';
import * as S from './RootPage.style';
import * as I from './RootPage.types';

import {
  auditLogsItem,
  getUserPermissions,
  marketplaceContractsItem,
  sidebarContractsItem,
  sidebarManualAaItem,
  sidebarPocsItem,
  sidebarCouponsItem,
  sidebarAccountsMarketplacesItem,
  sidebarMaintenanceWindowItem,
  topBarItemList,
} from './RootPage.utils';

const RootPage: React.FC = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const { isAuthenticated } = authState;
  const { tokenManager } = oktaAuth;

  const dispatch = useDispatch();
  const isAfterOktaLogin = useRouteMatch('/implicit/callback') || false;
  const [sidebarExtendedState, setSidebarExtendedState] = useState(true);
  const user = useSelector((state: StoreInterface) => state.rootPage.loggedUser);
  const rootPageLoading = useSelector((state: StoreInterface) => state.rootPage.rootPageLoading);
  const permissions = useSelector((state: StoreInterface) => state.rootPage.permissions);

  const setUserInsideStore = useCallback(async () => {
    const usr = await oktaAuth.getUser();

    dispatch({
      type: 'setUserName',
      payload: usr,
    });
  }, [dispatch, oktaAuth]);

  useEffect(() => {
    if (isAfterOktaLogin) {
      // this request is used to audit the login action, but only after an okta login (detected by /implicit/callback route)
      setUserInsideStore().then(() => api.post(`${buildUrl('bousers')}/session/login`));
    }
  }, [isAfterOktaLogin, setUserInsideStore]);

  useEffect(() => {
    if (isAuthenticated) {
      const addTokenToRequest = async (config) => {
        const tokens = await tokenManager.getTokens();

        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${tokens?.accessToken?.accessToken}`,
          },
        };
      };

      // used to check, refresh and/or add new token on every request
      api.interceptors.request.use(async (config) => addTokenToRequest(config));
      fileApi.interceptors.request.use(async (config) => addTokenToRequest(config));

      setUserInsideStore();
    }
  }, [isAuthenticated, tokenManager, setUserInsideStore]);

  useEffect(() => {
    const callFun = () => {
      if (isAuthenticated) {
        getUserPermissions(dispatch);
      }
    };

    callFun();
  }, [isAuthenticated]); // eslint-disable-line

  async function logOut() {
    await api.post(`${buildUrl('bousers')}/session/logout`).finally(async () => {
      showToast('You are now logged off', 'info');
      // this is used in order to audit the login action
      await oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/` });
      dispatch({ type: 'setLogOutState' });
    });
  }

  function buildUserItemList() {
    return [
      {
        label: 'LogOut',
        onClick: logOut,
        disabled: false,
      },
    ];
  }

  const checkAccessInSidebar = () => {
    const PERMISSIONS_ITEMS: I.PermissionItems[] = [
      { permission: CONTRACTS_READ, item: sidebarContractsItem },
      { permission: MARKETPLACE_CONTRACT_READ, item: marketplaceContractsItem },
      { permission: ACCOUNTS_MARKETPLACES_READ, item: sidebarAccountsMarketplacesItem },
      { permission: MANUAL_A_A_READ, item: sidebarManualAaItem },
      { permission: POCS_READ, item: sidebarPocsItem },
      { permission: AUDILOGS_READ, item: auditLogsItem },
      { permission: COUPONS_READ, item: sidebarCouponsItem },
      { permission: MAINTENANCE_WINDOW_READ, item: sidebarMaintenanceWindowItem }
    ];
    const permittedItems: object[] = PERMISSIONS_ITEMS.filter((pi) =>
      permissions.includes(pi.permission)
    ).map(({ item }) => item);

    return permittedItems;
  };

  return (
      <S.RootPageWrapper>
          <S.ContentWrapper isExpanded={sidebarExtendedState}>
              {isAuthenticated ? (
                  <S.AppBarContainer>
                      <AppBar
                          userName={user?.name || ''}
                          itemList={topBarItemList}
                          userProfileMenuItemList={buildUserItemList()}
            />
                  </S.AppBarContainer>
        ) : null}
              <S.PagesWrapper>
                  {rootPageLoading && isAuthenticated ? (
                      <S.LoadingWrapper>
                          Loading... Please wait.
                      </S.LoadingWrapper>
          ) : (
              <RoutesPage isAuthenticated={isAuthenticated} />
          )}
              </S.PagesWrapper>
          </S.ContentWrapper>
          {isAuthenticated ? (
              <>
                  <MainSideBar
                      items={checkAccessInSidebar()}
                      sidebarExtendedState={sidebarExtendedState}
                      toggleSidebar={() => setSidebarExtendedState(!sidebarExtendedState)}
          />
              </>
      ) : null}
          <StyledToastContainer />
      </S.RootPageWrapper>
  );
};

export default RootPage;
