import { theme } from '@redislabsdev/redis-ui-styles';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const { colors } = theme;

export const StyledTab = styled(Link)`
  ${({ selected }) =>
    selected &&
    css`
      pointer-events: none;
      color: ${colors.blue6};
      border-bottom: 3px solid ${colors.blue6};
      margin-bottom: -1px;
    `}
`;
