import React from 'react';
import { useSelector } from 'react-redux';

import { Tooltip } from '@redislabsdev/redislabs-ui-components';
import * as CS from '../../styles/common.style';
import TableStatus from '../../components/TableStatus/TableStatus';
import { displayFormattedDate } from '../contractsPage/contractsPage.utils';
import { StoreInterface } from '../../interfaces/storeInterfaces';

export const marketplaceContractsColumns = [
  {
    header: 'Contract ID',
    accessor: 'contractId',
    disableFilters: true,
    style: { width: '8%' },
  },
  {
    header: 'Customer name',
    accessor: 'customerName',
    style: { width: '30%' },
    disableFilters: true,
    Cell: ({ value }) => {
      return (
          <CS.TooltipWrapper>
              <Tooltip
                  tooltipContent={<CS.TooltipTextWithTooltip>
                      {value}
                  </CS.TooltipTextWithTooltip>}
                  placement="top"
                  trigger="hover"
                  textColor="blue2"
          >
                  <CS.TextWithTooltip>
                      {value}
                  </CS.TextWithTooltip>
              </Tooltip>
          </CS.TooltipWrapper>
      );
    },
  },
  {
    header: 'Start Date',
    accessor: 'startDate',
    disableFilters: true,
    style: { width: '8%' },
    Cell: ({ value }) => {
      return (<div>
          {displayFormattedDate(value)}
      </div>);
    },
  },
  {
    header: 'End Date',
    accessor: 'endDate',
    disableFilters: true,
    style: { width: '8%' },
    Cell: ({ value }) => {
      return (<div>
          {displayFormattedDate(value)}
      </div>);
    },
  },
  {
    header: 'Credit Amount',
    accessor: 'credit',
    disableFilters: true,
    style: { width: '10%' },
    Cell: ({ value }) => {
      return (<div>
          {`$ ${value || 0}`}
      </div>);
    },
  },
  {
    header: 'Credit Consumed',
    accessor: 'creditConsumed',
    disableFilters: true,
    style: { width: '10%' },
    Cell: ({ value }) => {
      return (<div>
          {`$ ${value || 0}`}
      </div>);
    },
  },
  {
    header: 'Over Usage',
    accessor: 'overUsage',
    disableFilters: true,
    style: { width: '8%' },
    Cell: ({ value }) => {
      return (<div>
          {`$ ${value || 0}`}
      </div>);
    },
  },
  {
    header: 'Marketplace',
    accessor: 'marketplace',
    disableFilters: true,
    disableSortBy: true,
    style: { width: '8%' },
    Cell: ({ value }) => {
      return (<div>
          {`${value}`}
      </div>);
    },
  },
  {
    header: 'Status',
    accessor: 'status',
    disableFilters: true,
    style: { width: '20rem' }, // keep it in absolute value so that the user can access the menus
    Cell: (props) => {
      const { value } = props;
      const marketplaceContractsStatuses = useSelector(
        (state: StoreInterface) => state.marketplaceContracts.marketplaceContractsStatuses
      );

      return (
          <CS.StatusWrapper>
              <TableStatus
                  status={value}
                  useFullWidth
                  statuses={marketplaceContractsStatuses}
                  customStatusWidth="17rem"
                  dataTestId="contract-status"
          />
          </CS.StatusWrapper>
      );
    },
  },
];
