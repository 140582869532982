import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment';

import {
  Button,
  DatePicker,
} from '@redislabsdev/redislabs-ui-components';
import { Input } from '@redislabsdev/redis-ui-components';
import { CalendarIcon, CancelIcon } from '@redislabsdev/redis-ui-icons';
import { StoreInterface } from '../../interfaces/storeInterfaces';
import { updateStateOnInputType } from '../../utils/common.utils';
import ClearAllAndApplyButtons from '../contractsPage/ClearAllAndApplyButtons';
import { displayFormattedDate } from '../contractsPage/contractsPage.utils';
import * as CS from '../../styles/common.style';
import { couponsFilterSectionLabels } from '../../constants/couponsPageConstants';
import * as S from './CouponsPage.styles';
import { getCouponsTableData, getCouponsConfiguration } from './CouponsPage.api';

const CouponsPageFilterSection: React.FC = () => {
  const dispatch = useDispatch();
  const filterItems = useSelector((state: StoreInterface) => state.couponsPage.couponsFilterItems);
  const utcDateStartOfDay = moment.utc().startOf('day');
  const couponStatuses = useSelector((state: StoreInterface) => state.couponsPage.couponStatuses);
  const loadCouponConfig = useSelector(
    (state: StoreInterface) => state.couponsPage.loadCouponConfig
  );
  const { couponCode, minAmount, expiryDateTo, status } = filterItems;

  const [showExpiryDatePicker, setShowExpiryDatePicker] = useState(false);
  const [currentExpiryMonth, setCurrentExpiryMonth] = useState<Moment>(utcDateStartOfDay);

  const updateStateOnInputTypeWrapper = (event) =>
    updateStateOnInputType({
      eTarget: event.target,
      action: 'setCouponsFilterItems',
      dispatch,
    });

  useEffect(() => {
    const callFun = async () => {
      if (couponStatuses.length <= 1) {
        await getCouponsConfiguration(dispatch);
      }
    };

    callFun();
  }, []); // eslint-disable-line

  return (
      <CS.FilterSectionWrapper>
          <CS.FilterSection singleRow>
              <CS.FilterRow>
                  <label htmlFor="couponCode">
                      {couponsFilterSectionLabels.couponCode}
                      <Input
                          autoComplete="new-coupon-code"
                          data-testid="textinput--coupon-code"
                          width="20rem"
                          placeholder="All"
                          type="text"
                          name="couponCode"
                          id="couponCode"
                          value={couponCode}
                          onChange={(event) => updateStateOnInputTypeWrapper(event)}
            />
                  </label>
                  <label htmlFor="minAmount">
                      {couponsFilterSectionLabels.couponAmount}
                      <Input
                          autoComplete="new-coupon-amount"
                          data-testid="textinput--coupon-amount"
                          width="20rem"
                          placeholder="All"
                          type="number"
                          name="minAmount"
                          id="minAmount"
                          value={minAmount}
                          onChange={(event) => updateStateOnInputTypeWrapper(event)}
            />
                  </label>
                  <label htmlFor="expiryDate">
                      <span>
                          {couponsFilterSectionLabels.expiryDate}
                      </span>
                      <CS.SpanWithDate>
                          <span id="expiryDate" data-testid="textinput--expiry-date">
                              {displayFormattedDate(expiryDateTo)}
                          </span>
                          <CS.SpanWithDateButtonsWrapper>
                              {expiryDateTo ? (
                                  <Button
                                      data-testid="button--clear-expiry-date"
                                      variant="secondary"
                                      size="small"
                                      onClick={() => {
                      dispatch({
                        type: 'clearIndividualDate',
                        payload: {
                          expiryDateTo: null,
                        },
                      });
                    }}
                  >
                                    <CancelIcon size="L" />
                                  </Button>
                ) : (
                    <Button
                        data-testid="button--expiry-date"
                        variant="secondary"
                        size="small"
                        onClick={() => {
                      setShowExpiryDatePicker(!showExpiryDatePicker);
                    }}
                  >
                      <CalendarIcon size="L" />
                    </Button>
                )}
                          </CS.SpanWithDateButtonsWrapper>
                          {showExpiryDatePicker ? (
                              <DatePicker
                                  showOnClickOutsideFn={() => {
                    setShowExpiryDatePicker(false);
                  }}
                                  currentMonth={currentExpiryMonth.toDate()}
                                  setCurrentMonth={(newMonth, keepPickerOpen) => {
                    setCurrentExpiryMonth(moment(newMonth).startOf('day'));
                    setShowExpiryDatePicker(keepPickerOpen);
                  }}
                                  currentDay={expiryDateTo?.toDate()}
                                  setCurrentDay={(newDate, keepPickerOpen) => {
                    dispatch({
                      type: 'setCouponsFilterItems',
                      payload: {
                        expiryDateTo: moment(newDate as Date),
                      },
                    });
                    setShowExpiryDatePicker(keepPickerOpen);
                  }}
                />
              ) : null}
                      </CS.SpanWithDate>
                  </label>
                  <label htmlFor="status">
                      {loadCouponConfig ? (
                          <CS.StatusLoading>
                              Loading...
                          </CS.StatusLoading>
            ) : (
                <>
                    {couponsFilterSectionLabels.status}
                    <S.CouponsStatusDropdownMenu
                        dataTestIdSuffix="dropdown-status"
                        items={couponStatuses}
                        selectedItem={status}
                        renderItem={(item) => (<div>
                            {item.label}
                        </div>)}
                        onSelectedItemChange={(item) => {
                    dispatch({
                      type: 'setCouponsFilterItems',
                      payload: { status: item.selectedItem },
                    });
                  }}
                />
                </>
            )}
                  </label>
              </CS.FilterRow>
              <ClearAllAndApplyButtons
                  applyButtonCallback={() => getCouponsTableData(filterItems, dispatch)}
                  clearAllCallback={() =>
            dispatch({
              type: 'resetCouponsPageFilterItems',
            })
          }
        />
          </CS.FilterSection>
      </CS.FilterSectionWrapper>
  );
};

export default CouponsPageFilterSection;
