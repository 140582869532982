import { Button, Modal } from '@redislabsdev/redis-ui-components';
import { showToast } from '@redislabsdev/redislabs-ui-components';
import { parseISO } from 'date-fns';
import { Formik } from 'formik';
import moment from 'moment';
import { useState } from 'react';
import styled from 'styled-components';
import { array, date, number, object, ref, string } from 'yup';
import ProtectedComponent from '../../components/ProtectedComponent/ProtectedComponent';
import { MAINTENANCE_WINDOW_WRITE } from '../../constants/permissionsConstants';
import ExclusionForm from './ExclusionForm';
import ExclusionTable from './ExclusionTable';
import ExclusionTableFilters from './ExclusionTableFilters';
import {
  ExclusionFormState,
  MaintenanceWindowExclusionsFilters,
  Sort,
} from './MaintenanceWindow.types';
import {
  AddExclusionRequest,
  EditExclusionRequest,
  Exclusion,
  addExclusionRequest,
  deleteExclusionRequest,
  editExclusionRequest,
  getExclusionTableDataApiRequest,
} from './MaintenanceWindowPage.api';
import { TableControls } from './Table.style';
import formatInTimeZone from './formatInTimeZone';

const ErrorComponent = styled.div`
  padding: 0.8rem 1.2rem;
  border-radius: 0.8rem;
  width: fit-content;
  border-width: 0.1rem;
  border-style: solid;
  background-color: #feedee;
  color: #631e23;
  border-color: #f98189;
  margin-top: 1.2rem;
  width: 100%;
`;

const FormWrapper = styled.div`
  width: 60rem;
`;

const formatDate = (date: string) => formatInTimeZone(parseISO(date), 'd-MMM-y HH:mm:ss', 'UTC');

type ExclusionsProps = {
  filters: MaintenanceWindowExclusionsFilters;
  handleFiltersSubmit: (values: MaintenanceWindowExclusionsFilters) => void;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  sort: Sort | null;
  setSort: React.Dispatch<React.SetStateAction<Sort | null>>;
};

const addExclusionSchema = object().shape(
  {
    rcpId: number()
      .min(1)
      .when('meshId', {
        is: (meshId) => !meshId,
        then: number().required('rcpId or meshId is required'),
      })
      .when('meshId', {
        is: (meshId) => meshId > 0,
        then: number().max(0, 'rcpId and meshId cannot both be defined'),
      }),
    meshId: number()
      .min(1)
      .when('rcpId', {
        is: (rcpId) => !rcpId,
        then: number().required('rcpId or meshId is required'),
      })
      .when('rcpId', {
        is: (rcpId) => rcpId > 0,
        then: number().max(0, 'rcpId and meshId cannot both be defined'),
      }),
    operations: array().required().min(1),
    reason: string().max(3000),
    startDate: date().required(),
    endDate: date().required().min(ref('startDate'), "end date can't be before start date"),
  },
  ['rcpId', 'meshId']
);

const editExclusionSchema = object().shape({
  operations: array().required().min(1),
  reason: string().max(3000),
  startDate: date().required(),
  endDate: date().required().min(ref('startDate'), "end date can't be before start date"),
});

const initialStartDate = moment.utc(new Date()).toDate();
initialStartDate.setUTCHours(0, 0, 0, 0);

const initialEndDate = moment.utc(new Date()).toDate();
initialEndDate.setUTCHours(23, 59, 59, 999);

const initialExclusionFormValues: ExclusionFormState = {
  rcpId: '',
  meshId: '',
  operations: [],
  reason: '',
  startDate: initialStartDate,
  endDate: initialEndDate,
};

const initialPageSize = 10;

const Exclusions: React.FC<ExclusionsProps> = ({
  filters,
  handleFiltersSubmit,
  page,
  setPage,
  sort,
  setSort,
}) => {
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [editValues, setEditValues] = useState<Exclusion>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteValues, setDeleteValues] = useState<Exclusion>();
  const [exclusions, setExclusions] = useState<Exclusion[]>([]);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [size, setSize] = useState(initialPageSize);

  // The MultiSelect component triggers a form submit when hitting the delete all button
  // This flag prevents that component from submitting the form
  const [shouldSubmitForm, setShouldSubmitForm] = useState(false);

  const onAddClick = () => {
    setIsAddFormOpen(true);
  };

  const onEditClick = (row: Exclusion) => {
    setEditValues(row);
    setIsEditFormOpen(true);
  };

  const onDeleteClick = (row: Exclusion) => {
    setDeleteValues(row);
    setIsDeleteModalOpen(true);
  };

  const onSubmitClick = () => {
    setShouldSubmitForm(true);
  };

  const fetchExclusions = (inputPage = page) => {
    setLoading(true);
    getExclusionTableDataApiRequest(inputPage, size, filters, sort)
      .then((result) => {
        setExclusions(result.data.records);
        setTotalRecords(result.data.totalRecords);
      })
      .catch(() => {
        showToast('Error fetching data');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editFormValues: ExclusionFormState = editValues
    ? {
        rcpId: editValues?.rcpId?.toString() || '',
        meshId: editValues?.meshId?.toString() || '',
        operations: editValues?.operations || [],
        reason: editValues?.reason || '',
        startDate: editValues?.start ? new Date(editValues.start) : new Date(),
        endDate: editValues?.end ? new Date(editValues.end) : new Date(),
      }
    : initialExclusionFormValues;

  const handleAddExclusionSubmit = async (values: ExclusionFormState) => {
    if (!shouldSubmitForm) {
      return;
    }

    try {
      const exclusion: AddExclusionRequest = {
        rcpId: values.rcpId ? parseInt(values.rcpId, 10) : undefined,
        meshId: values.meshId ? parseInt(values.meshId, 10) : undefined,
        operations: values.operations,
        reason: values.reason || undefined,
        start: values.startDate.toISOString(),
        end: values.endDate.toISOString(),
      };
      await addExclusionRequest(exclusion);
      setShouldSubmitForm(false);
      setIsAddFormOpen(false);
      setError(undefined);
    } catch (e) {
      const errorMessage = (e as { response: { data: { error: string } } }).response.data.error;
      setError(errorMessage);
    }
  };

  const handleEditExclusionSubmit = async (values: ExclusionFormState) => {
    if (!shouldSubmitForm || !editValues?.exclusionId) {
      return;
    }

    try {
      const exclusion: EditExclusionRequest = {
        exclusionId: editValues.exclusionId,
        operations: values.operations,
        reason: values.reason || undefined,
        start: values.startDate.toISOString(),
        end: values.endDate.toISOString(),
      };
      await editExclusionRequest(exclusion);
      setIsEditFormOpen(false);
      setShouldSubmitForm(false);
      setError(undefined);
    } catch (e) {
      const errorMessage = (e as { response: { data: { error: string } } }).response.data.error;
      setError(errorMessage);
    }
  };

  const handleDeleteExclusionSubmit = async () => {
    if (!deleteValues?.exclusionId) {
      return;
    }

    setLoading(true);

    try {
      await deleteExclusionRequest(deleteValues?.exclusionId);

      // decrement page if we have deleted the last item on page
      const newPageNumber = page > 0 && exclusions.length === 1 ? page - 1 : page;
      setPage(newPageNumber);

      fetchExclusions(newPageNumber);
    } catch (error) {
      showToast('An error occurred while deleting exclusion.');
      setLoading(false);
    }
  };

  if (isAddFormOpen) {
    return (
      <FormWrapper>
        {error && <ErrorComponent>{error}</ErrorComponent>}
        <Formik
          initialValues={initialExclusionFormValues}
          validationSchema={addExclusionSchema}
          onSubmit={handleAddExclusionSubmit}
        >
          <ExclusionForm
            mode="add"
            onFormClose={() => {
              setIsAddFormOpen(false);
              setError(undefined);
            }}
            onSubmitClick={onSubmitClick}
          />
        </Formik>
      </FormWrapper>
    );
  }

  if (isEditFormOpen) {
    return (
      <FormWrapper>
        {error && <ErrorComponent>{error}</ErrorComponent>}
        <Formik
          initialValues={editFormValues}
          validationSchema={editExclusionSchema}
          onSubmit={handleEditExclusionSubmit}
        >
          <ExclusionForm
            mode="edit"
            onFormClose={() => {
              setIsEditFormOpen(false);
              setError(undefined);
            }}
            onSubmitClick={onSubmitClick}
          />
        </Formik>
      </FormWrapper>
    );
  }

  return (
    <>
      <Modal.Compose open={isDeleteModalOpen} onOpenChange={setIsDeleteModalOpen}>
        <Modal.Content.Compose
          style={{
            width: '50rem',
          }}
        >
          <Modal.Content.Header title="Delete Exclusion?" />
          <Modal.Content.Body.Compose>
            <ul>
              {deleteValues?.rcpId && <li>RCP ID: {deleteValues.rcpId}</li>}
              {deleteValues?.meshId && <li>Mesh ID: {deleteValues.meshId}</li>}
              {deleteValues?.accountId && <li>Account ID: {deleteValues.accountId}</li>}
              {deleteValues?.subscriptionId && (
                <li>Subscription ID: {deleteValues.subscriptionId}</li>
              )}
              {deleteValues?.start && <li>Expiration Start: {formatDate(deleteValues.start)}</li>}
              {deleteValues?.end && <li>Expiration End: {formatDate(deleteValues.end)}</li>}
              {deleteValues?.operations && <li>Operations: {deleteValues.operations.join(', ')}</li>}
              {deleteValues?.reason && <li>Reason: {deleteValues.reason}</li>}
            </ul>
          </Modal.Content.Body.Compose>
          <Modal.Content.Footer
            onSubmit={handleDeleteExclusionSubmit}
            submitText="Confirm"
            cancelText="Cancel"
          />
        </Modal.Content.Compose>
      </Modal.Compose>
      <ExclusionTableFilters initialFilterValues={filters} handleSubmit={handleFiltersSubmit} />
      <TableControls>
        <ProtectedComponent requiredPermissions={MAINTENANCE_WINDOW_WRITE}>
          <Button onClick={onAddClick} style={{ marginRight: '1rem' }}>
            Add Exclusion
          </Button>
        </ProtectedComponent>
      </TableControls>
      <ExclusionTable
        exclusions={exclusions}
        fetchExclusions={fetchExclusions}
        loading={loading}
        totalRecords={totalRecords}
        size={size}
        setSize={setSize}
        filters={filters}
        page={page}
        setPage={setPage}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
        sort={sort}
        setSort={setSort}
      />
    </>
  );
};

export default Exclusions;
