import omit from 'lodash/omit';
import { api, buildUrl } from '../../api/config';
import {
  EmailTemplateFormState,
  MaintenanceWindowClustersFilters,
  MaintenanceWindowExclusionsFilters,
  Sort,
} from './MaintenanceWindow.types';

export type MaintenanceWindow = {
  days: number[];
  from: number;
  duration: number;
};

export type Cluster = {
  accountId: number;
  rcpId: number | null;
  meshId: number | null;
  clusterId: number | null;
  subscriptionId: number;
  swVersion: string | null;
  optInVersion: string | null;
  optInRequestTime: string | null;
  cloudProviderName: string;
  regionName: string;
  offSm: boolean;
  clusterNotes: string | null;
  nodeCount: number | null;
  clusterUrl: string | null;
  maintenanceMode: boolean;
  maintenanceWindows: MaintenanceWindow[];
  lastMaintenanceActivity?: {
    timestamp: string;
    operations: string[];
    includeSystemLog: boolean;
    includeEmail: boolean;
    setMaintenanceMode: boolean;
    sendgridId: string;
    internalNotes: string;
  } | null;
  excludedOperations: string[];
};

export type Exclusion = {
  exclusionId: number;
  accountId: number;
  rcpId: number | null;
  meshId: number | null;
  subscriptionId: number;
  start: string;
  end: string;
  operations: string[];
  reason: string | null;
  initiator: 'Ops' | 'Customer';
};

export type ClustersResponse = {
  records: Cluster[];
  totalRecords: number;
};

export type EmailTemplatesResponse = {
  email_template_id: number;
  friendly_name: string;
  sendgrid_id: string;
  maintenance_type: string;
  is_urgent: boolean;
};

export type MaintenanceEvent = {
  rcpId?: string | null;
  meshId?: string | null;
  maintenanceType: string;
  operations: string[];
  isUrgent: boolean;
  includeSystemLog: boolean;
  includeEmail: boolean;
  setMaintenanceMode: boolean;
  internalNotes?: string | null;
  emailTemplate?: string;
};

export type ExclusionsResponse = {
  records: Exclusion[];
  totalRecords: number;
};

export type AddExclusionRequest = {
  rcpId?: number;
  meshId?: number;
  operations: string[];
  reason?: string;
  start: string;
  end: string;
};

export type EditExclusionRequest = {
  exclusionId: number;
  operations: string[];
  reason?: string;
  start: string;
  end: string;
};

const clustersArrayFilterFields = ['providers', 'regions', 'maintenanceActivity'];
const exclusionsArrayFilterFields = ['operations'];

export const buildArrayFilters = (
  filters: MaintenanceWindowClustersFilters | MaintenanceWindowExclusionsFilters,
  arrayFilterFields: string[]
) => {
  const filterString = Object.keys(filters)
    .filter((key) => arrayFilterFields.includes(key) && filters[key].length)
    .reduce((all, key) => {
      const prefix = all ? '&' : '?';

      const values = filters[key].map((value) => `${key}[]=${encodeURIComponent(value)}`);
      return `${all}${prefix}${values.join('&')}`;
    }, '');

  return filterString;
};

export const blankFilters = (
  filters: MaintenanceWindowClustersFilters | MaintenanceWindowExclusionsFilters
) =>
  Object.entries(filters)
    .filter(([_, value]) => value === 'blank')
    .map(([key]) => key);

export const getMaintenanceWindowTableDataApiRequest = (
  page: number,
  size: number,
  filters: MaintenanceWindowClustersFilters,
  sort: Sort | null
) =>
  api.get<ClustersResponse>(
    `${buildUrl('maintenanceWindow')}/clusters${buildArrayFilters(
      filters,
      clustersArrayFilterFields
    )}`,
    {
      params: {
        page,
        size,
        ...omit(filters, clustersArrayFilterFields, blankFilters(filters)),
        ...sort,
      },
    }
  );

export const createMaintenanceEventRequest = (values: MaintenanceEvent) =>
  api.post(`${buildUrl('maintenanceWindow')}/maintenance-activity`, values);

export const getEmailTemplatesRequest = () =>
  api.get<EmailTemplatesResponse[]>(`${buildUrl('maintenanceWindow')}/email-templates`);

export const createEmailTemplateRequest = (values: EmailTemplateFormState) =>
  api.post(`${buildUrl('maintenanceWindow')}/email-template`, values);

export const updateEmailTemplateRequest = (values: EmailTemplateFormState) =>
  api.put(
    `${buildUrl('maintenanceWindow')}/email-template/${values.email_template_id}`,
    omit(values, 'email_template_id')
  );

export const deleteEmailTemplateRequest = (email_template_id: number) =>
  api.delete(`${buildUrl('maintenanceWindow')}/email-template/${email_template_id}`);

export const getExclusionTableDataApiRequest = (
  page: number,
  size: number,
  filters: MaintenanceWindowExclusionsFilters,
  sort: Sort | null
) =>
  api.get<ExclusionsResponse>(
    `${buildUrl('maintenanceWindow')}/exclusions${buildArrayFilters(
      filters,
      exclusionsArrayFilterFields
    )}`,
    {
      params: {
        page,
        size,
        ...omit(filters, exclusionsArrayFilterFields, blankFilters(filters)),
        ...sort,
      },
    }
  );

export const updateClusterNotes = (clusterId: number, clusterNotes: string) =>
  api.put(`${buildUrl('maintenanceWindow')}/clusters/${clusterId}/notes`, {
    clusterNotes,
  });

export const addExclusionRequest = (exclusion: AddExclusionRequest) =>
  api.post(`${buildUrl('maintenanceWindow')}/exclusions`, exclusion);

export const editExclusionRequest = (exclusion: EditExclusionRequest) =>
  api.put(
    `${buildUrl('maintenanceWindow')}/exclusions/${exclusion.exclusionId}`,
    omit(exclusion, 'exclusionId')
  );

export const deleteExclusionRequest = (exclusionId: number) =>
  api.delete(`${buildUrl('maintenanceWindow')}/exclusions/${exclusionId}`);
