export const CONTRACTS_READ = 'CONTRACTS_READ';
export const CONTRACTS_WRITE = 'CONTRACTS_WRITE';

export const MANUAL_A_A_READ = 'MANUAL_A_A_READ';
export const MANUAL_A_A_WRITE = 'MANUAL_A_A_WRITE';

export const POCS_READ = 'POCS_READ';
export const POCS_WRITE = 'POCS_WRITE';

export const COUPONS_READ = 'COUPONS_READ';
export const COUPONS_WRITE = 'COUPONS_WRITE';

export const AUDILOGS_READ = 'AUDIT_READ';
// export const AUDILOGS_WRITE = 'AUDIT_READ';

export const MARKETPLACE_CONTRACT_READ = 'MARKETPLACE_READ';
export const MARKETPLACE_CONTRACT_WRITE = 'MARKETPLACE_WRITE';

export const ACCOUNTS_MARKETPLACES_READ = 'ACCOUNTS_MARKETPLACES_READ';
export const ACCOUNTS_MARKETPLACES_WRITE = 'ACCOUNTS_MARKETPLACES_WRITE';

export const MAINTENANCE_WINDOW_READ = 'MAINTENANCE_WINDOW_READ';
export const MAINTENANCE_WINDOW_WRITE = 'MAINTENANCE_WINDOW_WRITE';

export const MAINTENANCE_EVENT_READ = 'MAINTENANCE_EVENT_READ';
export const MAINTENANCE_EVENT_WRITE = 'MAINTENANCE_EVENT_WRITE';
export const MAINTENANCE_EMAIL_TEMPLATE_READ = 'MAINTENANCE_EMAIL_TEMPLATE_READ';
export const MAINTENANCE_EMAIL_TEMPLATE_WRITE = 'MAINTENANCE_EMAIL_TEMPLATE_WRITE';
