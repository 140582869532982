import React from 'react';
import { useHistory } from 'react-router-dom';
import { EllipsisMenu, Tooltip } from '@redislabsdev/redislabs-ui-components';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import TableStatus from '../../components/TableStatus/TableStatus';
import { prepareFilterTableRequestData } from '../../utils/filterTable.utils';
import { displayErrors } from '../rootPage/RootPage.utils';
import * as CS from '../../styles/common.style';
import { api, buildUrl } from '../../api/config';
import { showToast } from '../../components/Toast/Toast';
import { CONTRACTS_WRITE } from '../../constants/permissionsConstants';
import { StoreInterface } from '../../interfaces/storeInterfaces'; // eslint-disable-line

export const TABLE_DEFAULT_SORT = [{ id: 'contractId', desc: true }];

export const handleDayClick = (props) => {
  const { dispatch, newDate, key, dispatchType } = props;

  if (newDate.from && newDate.to) {

    // Convert to UTC while preserving the same day, month, and year
    const utcFromDate = moment(newDate.from).utcOffset(0, true);
    const utcToDate = moment(newDate.to).utcOffset(0, true);

    dispatch({
      type: dispatchType,
      payload: {
        [`${key}From`]: utcFromDate,
        [`${key}To`]: utcToDate,
      },
    });
  }
};

export async function getAccountsConf(dispatch) {
  try {
    const req = await api.get(`${buildUrl('bousers')}/configuration/contracts`);

    if (req.status === 200) {
      dispatch({ type: 'contractsConfig', payload: req.data });
      return req.data;
    }
  } catch (err) {
    displayErrors(err, 'Failed trying to get configuration for Contracts Page');
  }
  return null;
}

export async function getPaymentInfoConfig(dispatch) {
  try {
    const req = await api.get(`${buildUrl('bousers')}/configuration/payment-info`);

    if (req.status === 200) {
      dispatch({ type: 'paymentInfoConfig', payload: req.data });
      return req.data;
    }
  } catch (err) {
    displayErrors(err, 'Failed trying to get configuration for Contracts Page');
  }
  return null;
}

export async function getCountriesStates(dispatch) {
  try {
    const req = await api.get(`${buildUrl('bousers')}/configuration/countries-states`);

    if (req.status === 200) {
      dispatch({ type: 'countriesStates', payload: req.data });
      dispatch({ type: 'loadingCountriesStates', payload: false });
      return req.data;
    }
  } catch (err) {
    displayErrors(err, 'Failed trying to get countries/states for Contracts Page');
  }
  return null;
}

const tableDataRequest = (params) =>
  api.get(`${buildUrl('contracts')}`, {
    params: {
      ...params,
    },
  });

export async function getTableData(reqParams, dispatch) {
  dispatch({ type: 'loadingContractsTableData', payload: true });
  dispatch({
    type: 'setFilterItems',
    payload: {
      pageIndex: reqParams.pageIndex,
      pageSize: reqParams.pageSize,
      offset: reqParams.offset,
      sortBy: reqParams.sortBy,
      sortDirection: reqParams.sortDirection,
    },
  });

  const newParams = {
    ...reqParams,
    status: reqParams.status.code,
  };

  tableDataRequest(prepareFilterTableRequestData(newParams, ['pageCount', 'pageIndex']))
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: 'setFilterItems',
          payload: {
            pageCount: Math.ceil(data.itemsTotal / reqParams.pageSize),
          },
        });

        dispatch({ type: 'setContractsTableData', payload: data.items });
      }
    })
    .finally(() => {
      dispatch({ type: 'loadingContractsTableData', payload: false });
    })
    .catch((err) => {
      displayErrors(err, 'Failed trying to get contracts table data');
    });
}

export async function saveContractFun(props) {
  const { reqParams, callBack, expand, contractId } = props;

  try {
    const removeEmptyParams = R.compose(
      R.reject(R.isEmpty), // removes empty keys
      R.omit(['accWithDetails', 'remainingCredit']) // removes specific key as it is used in FE only
    )(reqParams.contractInfo);

    const pickedAccountsToSend = {};
    Object.keys(reqParams.contractInfoPickedAccounts).map((key) => {
      pickedAccountsToSend[key] = {
        accountId: reqParams.contractInfoPickedAccounts[key].accountId,
        paymentInfoId:
          reqParams.contractInfoPickedAccounts[key].tempPaymentInfoId?.id ??
          reqParams.contractInfoPickedAccounts[key].paymentInfoId,
      };
      return null;
    });

    const dataToSend = {
      ...removeEmptyParams,
      redislabsEmailList:
        removeEmptyParams?.redislabsEmailList &&
        R.map(R.trim, R.split(',', removeEmptyParams?.redislabsEmailList)),
      endDate: moment.utc(removeEmptyParams.endDate).endOf('day').valueOf(),
      startDate: moment.utc(removeEmptyParams.startDate).startOf('day').valueOf(),
      accounts: R.values(pickedAccountsToSend),
    };

    const buildRequest = () => {
      if (expand && contractId) {
        // expand a contract
        return api.put(`${buildUrl('contracts')}/${contractId}/expand`, dataToSend);
      }

      if (contractId) {
        // update existing contract
        return api.put(`${buildUrl('contracts')}/${contractId}`, dataToSend);
      }

      // create new contract
      return api.post(`${buildUrl('contracts')}`, dataToSend);
    };

    const response = await buildRequest();

    if (response.status === 200) {
      callBack(response.data?.contractId || contractId);
    }
  } catch (err) {
    displayErrors(err, 'There was a problem while trying to save the contract');
  }

  return null;
}

export async function getOneContract(contractId, dispatch) {
  try {
    // setLoadingContract
    dispatch({ type: 'setLoadingContract', payload: true });
    const response = await api.get(`${buildUrl('contracts')}/${contractId}`);

    if (response.status === 200) {
      const {
        redislabsEmailList,
        accounts,
        startDate,
        endDate,
        credit,
        creditConsumed,
      } = response.data;
      const newAccountsFormat = new Map(
        Object.entries(
          R.compose(
            R.mergeAll,
            R.map((el) => ({ [el.accountId]: el }))
          )(accounts)
        )
      );

      const newData = {
        ...response.data,
        redislabsEmailList: R.join(', ', redislabsEmailList),
        accounts: newAccountsFormat,
        startDate: moment.utc(startDate).startOf('day'),
        endDate: moment.utc(endDate).startOf('day'),
        remainingCredit: Math.max(credit - (creditConsumed || 0), 0),
      };

      dispatch({ type: 'contractInfo', payload: newData });
      dispatch({ type: 'setLoadingContract', payload: false });
    }
  } catch (err) {
    displayErrors(err, 'Failed trying to get Contract data');
  }

  return null;
}

export const displayFormattedDate = (displayedDate) => {
  if (!displayedDate) return 'All';

  return moment.utc(displayedDate).format('DD/MM/YYYY');
};

const requestGetAccDetails = (accId) =>
  api.get(`${buildUrl('accounts')}/${accId}/active-payment-info`);

export async function getAccDetails(accId, dispatch) {
  requestGetAccDetails(accId)
    .then(({ data, status }) => {
      if (status === 200) {
        dispatch({
          type: 'addAccountToPayment',
          payload: [
            `${accId}`,
            {
              accountId: `${accId}`,
              ...data,
            },
          ],
        });
      }
    })
    .catch((err) => {
      displayErrors(err, "Failed trying to get account info's");
    });
}

const requestWireTransfer = (props) => {
  const { accountId, newWireTransfer, viewType, paymentInfoId } = props;

  if (viewType === 'editWire') {
    return api.put(
      `${buildUrl('accounts')}/${accountId}/wire-transfer/${paymentInfoId}`,
      newWireTransfer
    );
  }
  return api.post(`${buildUrl('accounts')}/${accountId}/wire-transfer`, newWireTransfer);
};

export async function saveWireTransferDetails(props) {
  const { accountId, viewType, paymentInfoId, newWireTransfer, dispatch } = props;
  requestWireTransfer({ accountId, newWireTransfer, viewType, paymentInfoId })
    .then(async ({ status, data }) => {
      if (status === 200) {
        await getAccDetails(accountId, dispatch);
        showToast(
          `The payment method was successfully ${viewType === 'editWire' ? 'updated' : 'added'}`,
          'success'
        );
        dispatch({ type: 'resetWireTransferOptions' });
        dispatch({ type: 'resetWireTransfer' });
        dispatch({ type: 'setNewWireTransferId', payload: data.wireTransferId });
      }
    })
    .catch((err) => {
      displayErrors(err, 'Failed trying save wire-transfer');
    });
}

const graceContractRequest = (contractId, graceEndDate) =>
  api.put(`${buildUrl('contracts')}/${contractId}/grace`, { graceEndDate });

export async function graceContract(contractId, graceEndDate, successCallback) {
  graceContractRequest(contractId, graceEndDate)
    .then(async ({ status }) => {
      if (status === 200) {
        showToast(`Contract #${contractId} was graced successfully!`, 'success');
        successCallback();
      }
    })
    .catch((err) => {
      displayErrors(err, `Failed trying to grace Contract ${contractId}`);
    });
}

export const columnNames = [
  {
    header: 'Contract ID',
    accessor: 'contractId',
    disableFilters: true,
    width: 10,
  },
  {
    header: 'Customer name',
    accessor: 'customerName',
    width: 30,
    disableFilters: true,
    Cell: ({ value }) => {
      return (
        <CS.TooltipWrapper>
          <Tooltip
            tooltipContent={<CS.TooltipTextWithTooltip>
              {value}
            </CS.TooltipTextWithTooltip>}
            placement="top"
            trigger="hover"
            textColor="blue2"
          >
            <CS.TextWithTooltip>
              {value}
            </CS.TextWithTooltip>
          </Tooltip>
        </CS.TooltipWrapper>
      );
    },
  },
  {
    header: 'Start Date',
    accessor: 'startDate',
    disableFilters: true,
    width: 10,
    Cell: ({ value }) => {
      return (<div>
        {displayFormattedDate(value)}
      </div>);
    },
  },
  {
    header: 'End Date',
    accessor: 'endDate',
    disableFilters: true,
    width: 10,
    Cell: ({ value }) => {
      return (<div>
        {displayFormattedDate(value)}
      </div>);
    },
  },
  {
    header: 'NS Invoice',
    accessor: 'invoiceNumber',
    disableFilters: true,
    width: 12,
    Cell: ({ value }) => {
      return (
        <CS.TooltipWrapper>
          <Tooltip
            tooltipContent={<CS.TooltipTextWithTooltip>
              {value}
            </CS.TooltipTextWithTooltip>}
            placement="top"
            trigger="hover"
            textColor="blue2"
          >
            <CS.TextWithTooltip>
              {value}
            </CS.TextWithTooltip>
          </Tooltip>
        </CS.TooltipWrapper>
      );
    },
  },
  {
    header: 'Credit Amount',
    accessor: 'credit',
    disableFilters: true,
    width: 9,
    Cell: ({ value }) => {
      return (<div>
        {`$ ${value || 0}`}
      </div>);
    },
  },
  {
    header: 'Credit Consumed',
    accessor: 'creditConsumed',
    disableFilters: true,
    width: 12,
    Cell: ({ value }) => {
      return (<div>
        {`$ ${value || 0}`}
      </div>);
    },
  },
  {
    header: 'Over Usage',
    accessor: 'overUsage',
    disableFilters: true,
    width: 7,
    Cell: ({ value }) => {
      return (<div>
        {`$ ${value || 0}`}
      </div>);
    },
  },
  {
    header: 'Status',
    accessor: 'status',
    disableFilters: true,
    width: 10,
    Cell: (props) => {
      const { value, row, data } = props;
      const history = useHistory();
      const dispatch = useDispatch();
      const permissions = useSelector((state: StoreInterface) => state.rootPage.permissions);
      const cloudContractStatuses = useSelector(
        (state: StoreInterface) => state.contractsPage.accountsConfiguration.cloudContractStatuses
      );
      const canWriteContracts = permissions.includes(CONTRACTS_WRITE);

      const expansionOption = {
        itemText: 'Contract Expansion',
        onClickAction: () => {
          dispatch({ type: 'resetContractInfo' });
          return history.push(`/contracts/${row.values.contractId}/expand`);
        },
      };
      const editContractOption = {
        itemText: 'Edit Contract',
        onClickAction: () => {
          dispatch({ type: 'resetContractInfo' });
          return history.push(`/contracts/${row.values.contractId}/edit`);
        },
      };
      const graceContractOption = {
        itemText: 'Grace Period',
        onClickAction: () => {
          const contract = data[row.index];
          const dataToSend = {
            contractId: contract.contractId,
            endDate: moment.utc(contract.endDate).add(1, 'day').startOf('day'),
            selectedEndDate: moment.utc(contract.endDate).add(1, 'day').startOf('day'),
          };
          dispatch({ type: 'setGraceContractModalData', payload: dataToSend });
        },
      };
      const items =
        value === 'active' && canWriteContracts ? [expansionOption, editContractOption] : [];

      if (value === 'active' && canWriteContracts && !data[row.index].gracePeriod) {
        items.push(graceContractOption);
      }

      return (
        <CS.StatusWrapper data-testid="status-wrapper">
          <TableStatus
            customStatusWidth="100%"
            useFullWidth
            status={value}
            statuses={cloudContractStatuses}
            dataTestId="contract-status"
          />
          {items.length > 0 && (
            <EllipsisMenu items={items} dataTestIdSuffix="contract-table-actions" />
          )}
        </CS.StatusWrapper>
      );
    },
  },
];
