import { Button } from '@redislabsdev/redislabs-ui-components';
import styled from 'styled-components/macro';

const filterBarHeight = '9.6rem';

export const FilterSectionManualAa = styled.div`
  height: ${filterBarHeight};
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 0 16px #0000000f;
  border-radius: 0.8rem;
`;

export const ClearAllBtnWrapAa = styled.div`
  display: flex;
  align-self: center;
  margin-left: 3rem;
  padding-top: 1rem;
  height: ${filterBarHeight};

  & button {
    border: 0;
  }
`;

export const ApplyBtnWrap = styled.div`
  display: flex;
  align-self: center;
  margin-left: 3rem;
  padding-top: 1rem;

  & button {
    background-color: #d2defc;
    color: #253dac;
  }
`;

export const BdbsTableWrapper = styled.div`
  margin: 1.5rem 0.9rem;

  > div {
    width: 80rem;
  }

  & table tr {
    font-size: 1.55rem;
  }
`;

export const PlusButton = styled(Button)`
  display: flex;
  margin-left: auto;
  padding: 0;
  background: #7b90e0;
  border-radius: 50px;
  min-width: 3rem;
  height: 3rem;
`;
