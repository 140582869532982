import { OptionType } from '@redislabsdev/redis-ui-components';

export const blankOption = { id: 'blank', title: '' };

export const timeOptions = [{ id: '12:00 AM', title: '12:00 AM' }];

for (let i = 1; i < 12; i++) {
  timeOptions.push({ id: `${i}:00 AM`, title: `${i}:00 AM` });
}
timeOptions.push({ id: '12:00 PM', title: '12:00 PM' });
for (let i = 1; i < 12; i++) {
  timeOptions.push({ id: `${i}:00 PM`, title: `${i}:00 PM` });
}

export const excludedOptions = [
  blankOption,
  { id: 'true', title: 'True' },
  { id: 'false', title: 'False' },
];

export const providerOptions: OptionType[] = [
  { value: '1', label: 'AWS', checked: false, key: '1' },
  { value: '4', label: 'Azure', checked: false, key: '4' },
  { value: '6', label: 'GCP', checked: false, key: '6' },
];

export const offSMOptions = [
  blankOption,
  { id: 'true', title: 'True' },
  { id: 'false', title: 'False' },
];

export const manualMaintenanceWindowOptions = [
  blankOption,
  { id: 'true', title: 'True' },
  { id: 'false', title: 'False ' },
];

export const optInStatusOptions = [
  blankOption,
  { id: 'pending', title: 'Pending' },
  { id: 'complete', title: 'Complete' },
];

export const initiatorOptions = [
  blankOption,
  { id: 'ops', title: 'Ops' },
  { id: 'customer', title: 'Customer' },
];

export const operationsOptions: OptionType[] = [
  { value: 'optimization', label: 'Optimization', checked: false, key: 'optimization' },
  { value: 'upgrade', label: 'Upgrade', checked: false, key: 'upgrade' },
  { value: 'other', label: 'Other', checked: false, key: 'other' },
];

export const regionOptions: OptionType[] = [
  { value: '115', label: '[AWS] af-south-1', checked: false, key: '2' },
  { value: '64', label: '[AWS] ap-east-1', checked: false, key: '3' },
  { value: '6', label: '[AWS] ap-northeast-1', checked: false, key: '4' },
  { value: '116', label: '[AWS] ap-northeast-2', checked: false, key: '5' },
  { value: '117', label: '[AWS] ap-northeast-3', checked: false, key: '6' },
  { value: '30', label: '[AWS] ap-south-1', checked: false, key: '7' },
  { value: '5', label: '[AWS] ap-southeast-1', checked: false, key: '8' },
  { value: '15', label: '[AWS] ap-southeast-2', checked: false, key: '9' },
  { value: '63', label: '[AWS] ca-central-1', checked: false, key: '10' },
  { value: '118', label: '[AWS] cn-north-1', checked: false, key: '11' },
  { value: '119', label: '[AWS] cn-northwest-1', checked: false, key: '12' },
  { value: '29', label: '[AWS] eu-central-1', checked: false, key: '13' },
  { value: '62', label: '[AWS] eu-north-1', checked: false, key: '14' },
  { value: '120', label: '[AWS] eu-south-1', checked: false, key: '15' },
  { value: '4', label: '[AWS] eu-west-1', checked: false, key: '16' },
  { value: '60', label: '[AWS] eu-west-2', checked: false, key: '17' },
  { value: '61', label: '[AWS] eu-west-3', checked: false, key: '18' },
  { value: '121', label: '[AWS] me-south-1', checked: false, key: '19' },
  { value: '7', label: '[AWS] sa-east-1', checked: false, key: '20' },
  { value: '28', label: '[AWS] simulator-reg', checked: false, key: '21' },
  { value: '1', label: '[AWS] us-east-1', checked: false, key: '22' },
  { value: '59', label: '[AWS] us-east-2', checked: false, key: '23' },
  { value: '122', label: '[AWS] us-gov-east-1', checked: false, key: '24' },
  { value: '123', label: '[AWS] us-gov-west-1', checked: false, key: '25' },
  { value: '2', label: '[AWS] us-west-1', checked: false, key: '26' },
  { value: '3', label: '[AWS] us-west-2', checked: false, key: '27' },
  { value: '114', label: '[AWS] us-west-2-lax', checked: false, key: '28' },
  { value: '16', label: '[Azure] east-us', checked: false, key: '29' },
  { value: '17', label: '[Azure] west-us', checked: false, key: '30' },
  { value: '31', label: '[Azure] WEST-US-2', checked: false, key: '31' },
  { value: '32', label: '[GCE] asia-east1', checked: false, key: '32' },
  { value: '67', label: '[GCE] asia-east2', checked: false, key: '33' },
  { value: '33', label: '[GCE] asia-northeast1', checked: false, key: '34' },
  { value: '104', label: '[GCE] asia-northeast2', checked: false, key: '35' },
  { value: '127', label: '[GCE] asia-northeast3', checked: false, key: '36' },
  { value: '69', label: '[GCE] asia-south1', checked: false, key: '37' },
  { value: '34', label: '[GCE] asia-southeast1', checked: false, key: '38' },
  { value: '163', label: '[GCE] asia-southeast2', checked: false, key: '39' },
  { value: '71', label: '[GCE] australia-southeast1', checked: false, key: '40' },
  { value: '72', label: '[GCE] europe-north1', checked: false, key: '41' },
  { value: '35', label: '[GCE] europe-west1', checked: false, key: '42' },
  { value: '74', label: '[GCE] europe-west2', checked: false, key: '43' },
  { value: '75', label: '[GCE] europe-west3', checked: false, key: '44' },
  { value: '36', label: '[GCE] europe-west4', checked: false, key: '45' },
  { value: '113', label: '[GCE] europe-west6', checked: false, key: '46' },
  { value: '126', label: '[GCE] northameria-northeast1', checked: false, key: '47' },
  { value: '77', label: '[GCE] northamerica-northeast1', checked: false, key: '48' },
  { value: '95', label: '[GCE] southamerica-east1', checked: false, key: '49' },
  { value: '27', label: '[GCE] us-central1', checked: false, key: '50' },
  { value: '38', label: '[GCE] us-east1', checked: false, key: '51' },
  { value: '39', label: '[GCE] us-east4', checked: false, key: '52' },
  { value: '40', label: '[GCE] us-west1', checked: false, key: '53' },
  { value: '82', label: '[GCE] us-west2', checked: false, key: '54' },
  { value: '124', label: '[GCE] us-west3', checked: false, key: '55' },
  { value: '125', label: '[GCE] us-west4', checked: false, key: '56' },
  { value: '26', label: '[IBM SoftLayer] dallas', checked: false, key: '57' },
];
