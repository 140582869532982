import { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, useField, useFormikContext } from 'formik';
import moment from 'moment';
import { startCase, isEmpty } from 'lodash';
import {
  Button,
  DateRange,
  IconButton,
  Input,
  MultiSelect,
  Popover,
  RangeDatePicker,
  TextArea,
} from '@redislabsdev/redis-ui-components';
import { CalendarIcon, CancelIcon } from '@redislabsdev/redis-ui-icons';
import * as CS from '../../styles/common.style';
import { ExclusionFormState } from './MaintenanceWindow.types';
import { operationsOptions } from './Select.options';
import { DateButtonRow, StyledError, StyledFormColumn, StyledFormRow } from './ExclusionForm.style';
import formatInTimeZone from './formatInTimeZone';

const formatDate = (date: Date | undefined) =>
  date ? formatInTimeZone(date, 'd/M/y', 'UTC') : null;

const numberRegex = /^[0-9\b]+$/;

const onChangeHandler = (
  event: React.ChangeEvent<HTMLInputElement>,
  handleChange: React.ChangeEventHandler<HTMLInputElement>,
  regex?: RegExp
) => {
  if (!regex) {
    handleChange(event);
    return;
  }

  if (event.target.value === '' || regex.test(event.target.value)) {
    handleChange(event);
  }
};

type ExclusionFormProps = {
  mode: 'add' | 'edit';
  onFormClose: () => void;
  onSubmitClick: () => void;
};

const ExclusionForm: React.FC<ExclusionFormProps> = ({ mode, onFormClose, onSubmitClick }) => {
  const {
    values: formState,
    initialValues,
    errors,
    validateForm,
    resetForm,
    isSubmitting,
  } = useFormikContext<ExclusionFormState>();

  // for components that do not allow a 'name' prop to be passed
  const [, , startDateHelpers] = useField({ name: 'startDate' });
  const [, , endDateHelpers] = useField({ name: 'endDate' });
  const [, , operationsHelpers] = useField({ name: 'operations' });

  const [operationsOptionsState, setOperationsOptionsState] = useState(
    operationsOptions.map((option) =>
      formState.operations.includes(option.value) ? { ...option, checked: true } : option
    )
  );
  const [isOperationsDropdownOpen, setIsOperationsDropdownOpen] = useState(false);
  const [dateRangeState, setDateRangeState] = useState<DateRange>();
  const [showDatePopover, setShowDatePopover] = useState(false);

  useEffect(() => {
    validateForm(); // validation doesn't seem to be happening on formState change
  }, [formState]);

  return (
    <Form>
      <StyledFormColumn>
        <h2>{startCase(mode)} Exclusion</h2>
        <StyledFormRow>
          {mode === 'add' ? (
            <label htmlFor="rcpId">
              RCP ID
              <Field name="rcpId">
                {({ field: { name, value, onChange, onBlur } }) => (
                  <Input
                    type="text"
                    name={name}
                    value={value}
                    onChange={(e) => onChangeHandler(e, onChange, numberRegex)}
                    onBlur={onBlur}
                  />
                )}
              </Field>
              <ErrorMessage component={StyledError} name="rcpId" />
            </label>
          ) : (
            formState.rcpId && `RCP ID: ${formState.rcpId}`
          )}

          {mode === 'add' ? (
            <label htmlFor="meshId">
              Mesh ID
              <Field name="meshId">
                {({ field: { name, value, onChange, onBlur } }) => (
                  <Input
                    type="text"
                    name={name}
                    value={value}
                    onChange={(e) => onChangeHandler(e, onChange, numberRegex)}
                    onBlur={onBlur}
                  />
                )}
              </Field>
              <ErrorMessage component={StyledError} name="meshId" />
            </label>
          ) : (
            formState.meshId && `Mesh ID: ${formState.meshId}`
          )}
        </StyledFormRow>

        <label htmlFor="operations">
          Operations
          <MultiSelect
            onValueChange={(option, checked) => {
              const newOptions = operationsOptionsState.map((operationsOption) => {
                if (operationsOption.value === option) {
                  return {
                    ...operationsOption,
                    checked,
                  };
                }
                return operationsOption;
              });
              setOperationsOptionsState(newOptions);

              const selectedValues = newOptions
                .filter(({ checked }) => checked)
                .map(({ value }) => value);

              operationsHelpers.setTouched(true, false);
              operationsHelpers.setValue(selectedValues);
            }}
            options={operationsOptionsState}
            placeholder="Select"
            open={isOperationsDropdownOpen}
            onOpenChange={() => {
              operationsHelpers.setTouched(true, false);
              setIsOperationsDropdownOpen(!isOperationsDropdownOpen);
            }}
          />
          <ErrorMessage component={StyledError} name="operations" />
        </label>

        <label htmlFor="reason">
          Reason
          <Field name="reason">
            {({ field: { name, value, onChange, onBlur } }) => (
              <TextArea name={name} value={value} onChange={onChange} onBlur={onBlur} />
            )}
          </Field>
          <ErrorMessage component={StyledError} name="reason" />
        </label>

        <label htmlFor="dateRange">
          Date Range
          <CS.SpanWithDate useAsRange>
            <span>
              {formState.startDate
                ? `${formatDate(formState.startDate)} - ${formatDate(formState.endDate)}`
                : ''}
            </span>
            <CS.SpanWithDateButtonsWrapper>
              {formState.startDate ? (
                <Button
                  variant="secondary-invert"
                  size="small"
                  onClick={() => {
                    startDateHelpers.setValue('');
                    endDateHelpers.setValue('');
                  }}
                >
                  <CancelIcon size="L" />
                </Button>
              ) : (
                <Popover.Compose visible={showDatePopover} onVisibilityChange={setShowDatePopover}>
                  <Popover.Trigger>
                    <IconButton variant="secondary" icon={CalendarIcon} />
                  </Popover.Trigger>
                  <Popover.Content.Compose placement="bottom">
                    <Popover.Content.Body.Compose
                      style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                    >
                      <RangeDatePicker
                        selectedRange={dateRangeState}
                        onRangeSelect={(selectedRange) => {
                          setDateRangeState(selectedRange);
                        }}
                      />
                      <DateButtonRow>
                        <Button
                          variant="secondary-invert"
                          onClick={() => {
                            setShowDatePopover(false);
                          }}
                        >
                          Cancel
                        </Button>{' '}
                        <Button
                          variant="secondary-invert"
                          onClick={() => {
                            startDateHelpers.setValue(initialValues.startDate);
                            endDateHelpers.setValue(initialValues.endDate);
                          }}
                        >
                          Reset
                        </Button>{' '}
                        <Button
                          variant="primary"
                          onClick={() => {
                            const start = moment.utc(dateRangeState?.from).toDate();
                            start.setUTCHours(0, 0, 0, 0);

                            const end = moment
                              .utc(dateRangeState?.to || dateRangeState?.from)
                              .toDate();
                            end.setUTCHours(23, 59, 59, 999);

                            startDateHelpers.setValue(start);
                            endDateHelpers.setValue(end);

                            setShowDatePopover(false);
                          }}
                        >
                          Apply
                        </Button>
                      </DateButtonRow>
                    </Popover.Content.Body.Compose>
                  </Popover.Content.Compose>
                </Popover.Compose>
              )}
            </CS.SpanWithDateButtonsWrapper>
          </CS.SpanWithDate>
          <ErrorMessage component={StyledError} name="startDate" />
          <ErrorMessage component={StyledError} name="endDate" />
        </label>
      </StyledFormColumn>
      <div>
        <Button
          size="large"
          type="submit"
          disabled={isSubmitting}
          onClick={() => {
            if (isEmpty(errors)) {
              onSubmitClick();
            }
          }}
        >
          Submit
        </Button>{' '}
        <Button
          onClick={() => {
            resetForm();
            setOperationsOptionsState(operationsOptions);
            onFormClose();
          }}
          variant="secondary-ghost"
          size="large"
        >
          Cancel
        </Button>
      </div>
    </Form>
  );
};

export default ExclusionForm;
