import React from 'react';
import { SideBar } from '@redislabsdev/redislabs-ui-components';
import { Link } from 'react-router-dom';
import { FooterLinksContainer } from '../../pages/rootPage/RootPage.style';
import { PRIVACY_LINK, TERMS_LINK } from '../../constants/generalConstants';
import SideBarLink from './SideBarLink';

const MainSideBar = ({ toggleSidebar, sidebarExtendedState, items }) => {
  return (
      <SideBar
          isExpanded={sidebarExtendedState}
          style={{ width: '22rem' }}
          data-testid="sidebar--primary"
          data-state={sidebarExtendedState ? 'expanded' : 'confined'}
    >
          <SideBar.Header onToggle={toggleSidebar}>
              <Link to="/" title="BackOffice home page">
                  <SideBar.RedisLogo />
              </Link>
          </SideBar.Header>

          <SideBar.ItemsContainer>
              {items.map((item) => (
                  <SideBarLink key={item.title} {...item} />
        ))}
          </SideBar.ItemsContainer>

          <SideBar.Footer>
              <SideBar.Footer.MetaData>
                  <FooterLinksContainer>
                      <SideBar.Footer.Link href={TERMS_LINK} target="_blank" rel="noopener noreferrer">
                          Terms
                      </SideBar.Footer.Link>
                      {' | '}
                      <SideBar.Footer.Link href={PRIVACY_LINK} target="_blank" rel="noopener noreferrer">
                          Privacy
                      </SideBar.Footer.Link>
                  </FooterLinksContainer>
                  <SideBar.Footer.Text>
                      {` © ${new Date().getFullYear()} Redis | BO v${process.env.REACT_APP_VERSION}`}
                  </SideBar.Footer.Text>
              </SideBar.Footer.MetaData>
          </SideBar.Footer>
      </SideBar>
  );
};

export default MainSideBar;
