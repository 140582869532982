import { IconButton } from '@redislabsdev/redis-ui-components';
import { DeleteIcon, EditIcon, SortDownIcon, SortUpIcon } from '@redislabsdev/redis-ui-icons';
import { Loader } from '@redislabsdev/redislabs-ui-components';
import { TablePagination } from '@redislabsdev/redislabs-ui-components/ui/components/Table';
import classNames from 'classnames';
import { parseISO } from 'date-fns';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import ProtectedComponent from '../../components/ProtectedComponent/ProtectedComponent';
import { MAINTENANCE_WINDOW_WRITE } from '../../constants/permissionsConstants';
import { MaintenanceWindowExclusionsFilters, Sort, TableColumn } from './MaintenanceWindow.types';
import { Exclusion } from './MaintenanceWindowPage.api';
import { RightAlignCell, StyledPre, StyledTable, TableContainer } from './Table.style';
import formatInTimeZone from './formatInTimeZone';
import { getSortDirectionForColumn } from './getSortDirectionForColumn';

const renderDate = (date: string) =>
  date ? <div>{formatInTimeZone(parseISO(date), 'd-MMM-y HH:mm:ss', 'UTC')}</div> : null;

const tableColumns = ({ onEditClick, onDeleteClick }): TableColumn<Exclusion>[] => [
  {
    header: 'RCP ID',
    accessor: 'rcpId',
    sortable: true,
  },
  {
    header: 'Mesh ID',
    accessor: 'meshId',
    sortable: true,
  },
  {
    header: 'Subscription ID',
    accessor: 'subscriptionId',
    sortable: true,
  },
  {
    header: 'Account ID',
    accessor: 'accountId',
    sortable: true,
  },
  {
    header: 'Expiration Start Time',
    accessor: 'start',
    render: ({ start }: Exclusion) => renderDate(start),
    sortable: true,
  },
  {
    header: 'Expiration End Time',
    accessor: 'end',
    render: ({ end }: Exclusion) => renderDate(end),
    sortable: true,
  },
  {
    header: 'Operations',
    accessor: 'operations',
    render: ({ operations }) => <>{operations.join(', ')}</>,
  },
  {
    header: 'Exclusion Reason',
    accessor: 'reason',
    render: ({ reason }: Exclusion) => (reason ? <StyledPre>{reason}</StyledPre> : null),
  },
  {
    header: 'Initiator',
    accessor: 'initiator',
  },
  {
    header: '',
    cellComponent: RightAlignCell,
    render: (row) =>
      row.initiator === 'Ops' ? (
        <ProtectedComponent requiredPermissions={MAINTENANCE_WINDOW_WRITE}>
          <>
            <IconButton variant="primary" icon={EditIcon} onClick={() => onEditClick(row)} />
            <IconButton
              style={{ marginLeft: '1rem' }}
              variant="secondary"
              icon={DeleteIcon}
              onClick={() => onDeleteClick(row)}
            />
          </>
        </ProtectedComponent>
      ) : null,
  },
];

export type ExclusionTableProps = {
  exclusions: Exclusion[];
  fetchExclusions: () => void;
  totalRecords: number;
  size: number;
  setSize: Dispatch<SetStateAction<number>>;
  loading: boolean;
  filters: MaintenanceWindowExclusionsFilters;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  sort: Sort | null;
  setSort: React.Dispatch<React.SetStateAction<Sort | null>>;
  onEditClick: (row: Exclusion) => void;
  onDeleteClick: (row: Exclusion) => void;
};

const ExclusionTable: React.FC<ExclusionTableProps> = ({
  exclusions,
  fetchExclusions,
  loading = false,
  totalRecords = 0,
  size = 0,
  setSize,
  filters,
  page,
  setPage,
  sort,
  setSort,
  onEditClick,
  onDeleteClick,
}) => {
  const columns = tableColumns({ onEditClick, onDeleteClick });

  useEffect(() => {
    fetchExclusions();
  }, [page, size, filters, sort]);

  const setSortForColumn = (accessor: string) => () => {
    setPage(0);
    setSort({ sortBy: accessor, sortDirection: getSortDirectionForColumn(sort, accessor) });
  };

  const pageCount = Math.ceil(totalRecords / size);
  return (
    <div data-testid="exclusions">
      {loading && (
        <div data-testid="exclusions-fetching-indicator">
          <Loader />
        </div>
      )}
      <div hidden={loading}>
        <TableContainer>
          <StyledTable>
            <thead>
              <tr>
                {columns.map(({ header, accessor, sortable }, key) => {
                  const showDescendingArrow =
                    sort && sort.sortBy === accessor && sort.sortDirection === 'desc';
                  const arrow = showDescendingArrow ? (
                    <SortDownIcon size="M" />
                  ) : (
                    <SortUpIcon size="M" />
                  );
                  const classes = {
                    sortable,
                    sorted: sort && sort.sortBy === accessor,
                  };

                  return (
                    <th
                      key={key}
                      onClick={sortable && accessor ? setSortForColumn(accessor) : undefined}
                      className={classNames(classes)}
                    >
                      <div>
                        <div>{header}</div>
                        {sortable ? <div>{arrow}</div> : null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {!exclusions.length && (
                <tr>
                  <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                    No results found.
                  </td>
                </tr>
              )}
              {exclusions.map((row) => {
                const rowKey = `${row.exclusionId}`;
                return (
                  <tr key={rowKey}>
                    {columns.map((col, key) => {
                      const value = col.render
                        ? col.render(row)
                        : col.accessor && row[col.accessor];
                      if (col.cellComponent) {
                        const CellComponent = col.cellComponent;
                        return <CellComponent key={`${rowKey}-${key}`}>{value}</CellComponent>;
                      }

                      return <td key={`${rowKey}-${key}`}>{value}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </StyledTable>
        </TableContainer>
        <TablePagination
          isPaginatedControlled={true}
          rows={exclusions}
          canNextPage={page + 1 < pageCount}
          canPreviousPage={page > 0}
          pageIndex={page}
          pageCount={pageCount}
          pageSize={size}
          gotoPage={(pageIndex: number) => {
            setPage(pageIndex);
          }}
          nextPage={() => {
            setPage(page + 1);
          }}
          previousPage={() => {
            setPage(page - 1);
          }}
          setPageSize={(newSize: number) => {
            setSize(newSize);
          }}
        />
      </div>
    </div>
  );
};

export default ExclusionTable;
