import { Tooltip, Input } from '@redislabsdev/redislabs-ui-components';
import React, { useState, useEffect } from 'react';
import * as S from './Cells.style';

export default function EditableCell({
  value: initialValue,
  row,
  column: { id, type, hasTooltip },
  getActionProps,
  tempRowData,
  handleInputChange,
  inputErrorsHandler,
  idKey,
}) {
  const [isInputValid, setIsInputValid] = useState(false);
  const { editMode } = getActionProps(row.original[idKey]);

  const validateOnChange = (value: string) => {
    const inputValidator = inputErrorsHandler[id].validator;
    inputValidator.validate(value).catch((e) => {
      handleInputChange(id, value, e.message);
    });
  };

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    validateOnChange(target.value);
    handleInputChange(id, value, '');
  };

  const handleBlur = async ({ target: { value } }) => {
    const inputValidator = inputErrorsHandler[id].validator;
    const isInputValidRes = await inputValidator.isValid(value);
    setIsInputValid(isInputValidRes);
  };

  useEffect(() => {
    if (editMode) {
      handleInputChange(id, initialValue);
    } else if (isInputValid) {
      setIsInputValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue, editMode]);

  const renderNonEditableText = () => {
    return hasTooltip ? (
        <Tooltip
            tooltipContent={<S.TooltipText>
                {initialValue}
            </S.TooltipText>}
            placement="top"
            trigger="hover"
            textColor="blue2"
      >
            <S.TextWrapper>
                {initialValue}
            </S.TextWrapper>
        </Tooltip>
    ) : (
        <S.TextWrapper>
            {initialValue}
        </S.TextWrapper>
    );
  };

  return (
      <S.Cell editMode={editMode}>
          {editMode ? (
              <S.InputWrapper>
                  <Input
                      id={id}
                      name={id}
                      width="100%"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={tempRowData[id]}
                      placeholder={`Enter ${id}`}
                      inputTestId={`textinput--${id}`}
                      error={inputErrorsHandler[id].error}
                      isValid={isInputValid}
                      type={type}
          />
              </S.InputWrapper>
      ) : (
        renderNonEditableText()
      )}
      </S.Cell>
  );
}
