import React from 'react';
import ReactDom from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import { OktaAuth } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, theme } from '@redislabsdev/redislabs-ui-components/styles';
import { api, buildUrl } from './api/config';

import RootPage from './pages/rootPage/RootPage';
import store from './store';

// css import

import '@redislabsdev/redislabs-ui-components/styles/ui-components-styles.css';
import '@redislabsdev/redis-ui-styles/fonts.css';
import './styles/main.css';
import '@redislabsdev/redis-ui-styles/normalized-styles.css';

declare global {
  interface Window {
    store: object;
  }
}

if (process.env.NODE_ENV === 'development') {
  window.store = store.getState;
}

(async () => {
  try {
    const oktaConfig = await api.get(`${buildUrl('bousers')}/configuration/okta`);
    if (oktaConfig.status === 200) {
      // okta config object based on window.origin
      const oktaAuth = new OktaAuth({
        ...oktaConfig.data,
        redirectUri:
          process.env.NODE_ENV === 'development'
            ? 'http://localhost:3000/implicit/callback'
            : oktaConfig.data.redirectUri,
        scopes: ['openid', 'email', 'groups', 'profile'],
        pkce: true,
      });

      ReactDom.render(
          <Provider store={store}>
              <React.StrictMode>
                  <ThemeProvider theme={theme}>
                      <GlobalStyle />
                      <BrowserRouter>
                          <Switch>
                              <Security oktaAuth={oktaAuth}>
                                  <RootPage />
                              </Security>
                          </Switch>
                      </BrowserRouter>
                  </ThemeProvider>
              </React.StrictMode>
          </Provider>,
        document.getElementById('root')
      );
    }
  } catch (err) {
    console.error(err); // eslint-disable-line
    console.error('Failed trying to get configuration for Contracts Page'); // eslint-disable-line
    ReactDom.render(
        <div>
            There was a problem trying to get the Okta configuration.
        </div>,
      document.getElementById('root')
    );
  }
})();
