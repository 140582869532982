import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { LoginCallback, SecureRoute } from '@okta/okta-react';
import { useSelector } from 'react-redux';

import {
  AUDILOGS_READ,
  ACCOUNTS_MARKETPLACES_READ,
  ACCOUNTS_MARKETPLACES_WRITE,
  CONTRACTS_READ,
  CONTRACTS_WRITE,
  MARKETPLACE_CONTRACT_READ,
  MARKETPLACE_CONTRACT_WRITE,
  MANUAL_A_A_READ,
  POCS_READ,
  COUPONS_READ,
  MAINTENANCE_WINDOW_READ,
} from '../constants/permissionsConstants';
import { StoreInterface } from '../interfaces/storeInterfaces';
import LogInPage from './logInPage/LogInPage';
import ContractsPage from './contractsPage/ContractsPage';
import ContractPage from './contractsPage/contractPage/ContractPage';
import Home from './Home';
import SubscriptionsPage from './SubscriptionsPage';
import UsersPage from './UsersPage';
import AuditLogsPage from './auditLogsPage/AuditLogsPage';
import MarketplaceContractsPage from './marketplaceContractsPage/MarketplaceContractsPage';
import MarketplaceContractPage from './marketplaceContractsPage/marketplaceContractPage/MarketplaceContractPage';
import AccountsMarketplacesPage from './accountsMarketplacesPage/AccountsMarketplacesPage';
import PocsPage from './pocsPage/PocsPage';
import CouponsPage from './couponsPage/CouponsPage';
import ManualAa from './manualAaPage/ManualAaPage';
import MaintenanceWindowPage from './maintenanceWindowPage/MaintenanceWindowPage';

const RoutesPage = (props) => {
  const { isAuthenticated } = props;
  const permissions = useSelector((state: StoreInterface) => state.rootPage.permissions);

  const canReadContracts = permissions.includes(CONTRACTS_READ);
  const canWriteContracts = permissions.includes(CONTRACTS_WRITE);
  const canReadManualAA = permissions.includes(MANUAL_A_A_READ);
  const canReadPocs = permissions.includes(POCS_READ);
  const canReadCoupons = permissions.includes(COUPONS_READ);
  const canReadAuditLogs = permissions.includes(AUDILOGS_READ);
  const canReadMarketplaceContracts = permissions.includes(MARKETPLACE_CONTRACT_READ);
  const canWriteMarketplaceContracts = permissions.includes(MARKETPLACE_CONTRACT_WRITE);
  const canReadAccountsMarketplaces = permissions.includes(ACCOUNTS_MARKETPLACES_READ);
  const canWriteAccountsMarketplaces = permissions.includes(ACCOUNTS_MARKETPLACES_WRITE);
  const canReadMaintenanceWindow = permissions.includes(MAINTENANCE_WINDOW_READ);

  return (
      <>
          {/* contract section */}
          <SecureRoute path="/contracts/" exact>
              {canReadContracts ? <ContractsPage /> : <Redirect to="/home" />}
          </SecureRoute>
          <SecureRoute path="/contracts/new" exact>
              {canWriteContracts ? <ContractPage /> : <Redirect to="/home" />}
          </SecureRoute>
          <SecureRoute path="/contracts/:id/expand" exact>
              {canWriteContracts ? <ContractPage /> : <Redirect to="/home" />}
          </SecureRoute>
          <SecureRoute path="/contracts/:id/edit" exact>
              {canWriteContracts ? <ContractPage /> : <Redirect to="/home" />}
          </SecureRoute>
          <SecureRoute path="/contracts/:id/view" exact>
              {canReadContracts ? <ContractPage /> : <Redirect to="/home" />}
          </SecureRoute>
          {/* end of contract section */}

          {/* manual AA section */}
          <SecureRoute path="/manualAa" exact>
              {canReadManualAA ? <ManualAa /> : <Redirect to="/home" />}
          </SecureRoute>
          {/* end of manual AA section */}

          {/* poc section */}
          <SecureRoute path="/pocs" exact>
              {canReadPocs ? <PocsPage /> : <Redirect to="/home" />}
          </SecureRoute>
          {/* end of poc section */}

          {/* auditLogs section */}
          <SecureRoute path="/auditLogs" exact>
              {canReadAuditLogs ? <AuditLogsPage /> : <Redirect to="/home" />}
          </SecureRoute>
          {/* end of auditLogs section */}

          {/* coupon section */}
          <SecureRoute path="/coupons" exact>
              {canReadCoupons ? <CouponsPage /> : <Redirect to="/home" />}
          </SecureRoute>
          {/* end of coupon section */}

          {/* accounts marketplaces section */}
          <SecureRoute path="/accountsMarketplaces" exact>
              {canReadAccountsMarketplaces ? <AccountsMarketplacesPage /> : <Redirect to="/home" />}
          </SecureRoute>
          <SecureRoute path="/accountsMarketplaces/:id/view" exact>
              {canReadAccountsMarketplaces ? <AccountsMarketplacesPage /> : <Redirect to="/home" />}
          </SecureRoute>
          <SecureRoute path="/accountsMarketplaces/:id/status" exact>
              {canWriteAccountsMarketplaces ? <AccountsMarketplacesPage /> : <Redirect to="/home" />}
          </SecureRoute>
          {/* end of coupon section */}

          {/* marketplace contracts section */}
          <SecureRoute path="/marketplaceContracts" exact>
              {canReadMarketplaceContracts ? <MarketplaceContractsPage /> : <Redirect to="/home" />}
          </SecureRoute>
          <SecureRoute path="/marketplaceContracts/:id/view" exact>
              {canReadMarketplaceContracts ? <MarketplaceContractPage /> : <Redirect to="/home" />}
          </SecureRoute>
          <SecureRoute path="/marketplaceContracts/:id/edit" exact>
              {canWriteMarketplaceContracts ? <MarketplaceContractPage /> : <Redirect to="/home" />}
          </SecureRoute>
          {/* end of marketplace contracts section */}

          {/* maintenance window section */}
          <SecureRoute path="/maintenanceWindow/:tab" exact>
              {canReadMaintenanceWindow ? <MaintenanceWindowPage /> : <Redirect to="/home" />}
          </SecureRoute>
          {/* end of maintenance window section */}

          <SecureRoute path="/subscriptions" component={SubscriptionsPage} />
          <SecureRoute path="/users" component={UsersPage} />
          <SecureRoute path="/home" exact component={Home} />
          <Route exact path="/">
              {isAuthenticated ? <Redirect to="/home" /> : <LogInPage />}
          </Route>
          <Route path="/implicit/callback" component={LoginCallback} />
      </>
  );
};

export default RoutesPage;
