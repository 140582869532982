import { Tabs } from '@redislabsdev/redis-ui-components';
import { useState } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import ProtectedComponent, {
  useUserHasPermission,
} from '../../components/ProtectedComponent/ProtectedComponent';
import {
  MAINTENANCE_EMAIL_TEMPLATE_READ,
  MAINTENANCE_EMAIL_TEMPLATE_WRITE,
  MAINTENANCE_EVENT_WRITE,
} from '../../constants/permissionsConstants';
import * as CS from '../../styles/common.style';
import EmailTemplates from './EmailTemplates';
import Exclusions from './Exclusions';
import MaintenanceActivityFormWrapper from './MaintenanceActivityFormWrapper';
import {
  MaintenanceWindowClustersFilters,
  MaintenanceWindowExclusionsFilters,
  Sort,
} from './MaintenanceWindow.types';
import MaintenanceWindowTable from './MaintenanceWindowTable';
import MaintenanceWindowTableFilters from './MaintenanceWindowTableFilters';
import { StyledTab } from './Tabs.style';

const basePath = '/maintenanceWindow';

const buildTab = (key: string, title: string, selectedTab: string) => (
  <StyledTab selected={selectedTab === key} key={key} to={`${basePath}/${key}`}>
    {title}
  </StyledTab>
);

const MaintenanceWindowPage = () => {
  const { tab } = useParams<{ tab: string }>();

  const [clustersFilters, setClustersFilters] = useState<MaintenanceWindowClustersFilters>({});
  const [exclusionsFilters, setExclusionsFilters] = useState<MaintenanceWindowExclusionsFilters>(
    {}
  );

  const [clustersPage, setClustersPage] = useState(0);
  const [exclusionsPage, setExclusionsPage] = useState(0);

  const [clustersSort, setClustersSort] = useState<Sort | null>(null);
  const [exclusionsSort, setExclusionsSort] = useState<Sort | null>(null);

  const hasMaintenanceEventWritePermission = useUserHasPermission(MAINTENANCE_EVENT_WRITE);
  const hasEmailTemplatePermissions = useUserHasPermission(
    [MAINTENANCE_EMAIL_TEMPLATE_READ, MAINTENANCE_EMAIL_TEMPLATE_WRITE],
    true
  );

  const tabs = [
    buildTab('clusters', 'Clusters', tab),
    buildTab('exclusions', 'Exclusions', tab),
    ...(hasMaintenanceEventWritePermission
      ? [buildTab('maintenanceActivity', 'Maintenance Activity', tab)]
      : []),
    ...(hasEmailTemplatePermissions ? [buildTab('emailTemplates', 'Email Templates', tab)] : []),
  ];

  return (
    <>
      <CS.PageTitleAndActions>
        <CS.JustifyToLeft>
          <CS.PageHeaderTitle>Maintenance Window</CS.PageHeaderTitle>
        </CS.JustifyToLeft>
      </CS.PageTitleAndActions>

      <Tabs>
        <Tabs.Labels.Compose>{tabs.map((tab) => tab)}</Tabs.Labels.Compose>
      </Tabs>

      <Switch>
        <Route
          path={`${basePath}/clusters`}
          render={() => (
            <>
              <MaintenanceWindowTableFilters
                initialFilterValues={clustersFilters}
                handleSubmit={(values: MaintenanceWindowClustersFilters) => {
                  setClustersPage(0);
                  setClustersFilters({ ...values });
                  setClustersSort(null);
                }}
              />
              <MaintenanceWindowTable
                filters={clustersFilters}
                page={clustersPage}
                setPage={setClustersPage}
                sort={clustersSort}
                setSort={setClustersSort}
              />
            </>
          )}
        />
        <Route
          path={`${basePath}/exclusions`}
          render={() => (
            <Exclusions
              filters={exclusionsFilters}
              handleFiltersSubmit={(values: MaintenanceWindowExclusionsFilters) => {
                setExclusionsPage(0);
                setExclusionsFilters({ ...values });
                setClustersSort(null);
              }}
              page={exclusionsPage}
              setPage={setExclusionsPage}
              sort={exclusionsSort}
              setSort={setExclusionsSort}
            />
          )}
        />
        <Route
          path={`${basePath}/maintenanceActivity`}
          render={() => (
            <ProtectedComponent requiredPermissions={MAINTENANCE_EVENT_WRITE}>
              <MaintenanceActivityFormWrapper />
            </ProtectedComponent>
          )}
        />
        <Route
          path={`${basePath}/emailTemplates`}
          render={() => (
            <ProtectedComponent
              requiredPermissions={[
                MAINTENANCE_EMAIL_TEMPLATE_READ,
                MAINTENANCE_EMAIL_TEMPLATE_WRITE,
              ]}
              partialPermissions
            >
              <EmailTemplates />
            </ProtectedComponent>
          )}
        />
      </Switch>
    </>
  );
};

export default MaintenanceWindowPage;
