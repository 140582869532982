import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Table } from '@redislabsdev/redislabs-ui-components';
import { Input, RangeDatePicker, DateRange, Popover, IconButton } from '@redislabsdev/redis-ui-components';
import { CancelIcon, CalendarIcon, ResetIcon } from '@redislabsdev/redis-ui-icons';

import * as CS from '../../styles/common.style';
import { displayFormattedDate, handleDayClick } from '../contractsPage/contractsPage.utils';

import { updateStateOnInputType } from '../../utils/common.utils';
import { StoreInterface } from '../../interfaces/storeInterfaces';
import { marketplaceContractsColumns } from './MarketplaceContracts.utils';
import {
  getMarketplaceAccountsConf,
  getMarketplaceContractsTableData,
} from './MarketplaceContracts.api';
import * as S from './MarketplaceContracts.style';

const TABLE_DEFAULT_SORT = [{ id: 'contractId', desc: true }];

const MarketplaceContractsPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const marketplaceContractsFilterItems = useSelector(
    (state: StoreInterface) => state.marketplaceContracts.marketplaceContractsFilterItems
  );

  const marketplaceContractsTableData = useSelector(
    (state: StoreInterface) => state.marketplaceContracts.marketplaceContractsTableData
  );
  const loadingMarketplaceContractsTableData = useSelector(
    (state: StoreInterface) => state.marketplaceContracts.loadingMarketplaceContractsTableData
  );

  const marketplaceContractsStatuses = useSelector(
    (state: StoreInterface) => state.marketplaceContracts.marketplaceContractsStatuses
  );
  const marketplaceContractsLoadPocConfig = useSelector(
    (state: StoreInterface) => state.marketplaceContracts.marketplaceContractsLoadPocConfig
  );

  const {
    contractId,
    customerName,
    smAccountId,
    smAccountName,
    startDateFrom,
    startDateTo,
    endDateFrom,
    endDateTo,
    status,
    pageSize,
    pageIndex,
    pageCount,
  } = marketplaceContractsFilterItems;

  const [startDateRange, setStartDateRange] = useState<DateRange>();
  const [endDateRange, setEndDateRange] = useState<DateRange>();

  useEffect(() => {
    const callFun = async () => {
      if (marketplaceContractsStatuses.length <= 1) {
        await getMarketplaceAccountsConf(dispatch);
      }
    };

    callFun();
  }, []); // eslint-disable-line

  const updateStateOnInputTypeWrapper = (event) =>
    updateStateOnInputType({
      eTarget: event.target,
      action: 'setMarketplaceContractsFilterItems',
      dispatch,
      callback: () =>
        dispatch({
          type: 'setFormTouchedState',
          payload: true,
        }),
    });

  const fetchData = useCallback(
    ({ pageIndex: internalPageIndex, pageSize: internalPageSize, sortBy: internalSortBy }) => {
      const { id, desc } = internalSortBy[0];

      getMarketplaceContractsTableData(
        {
          ...marketplaceContractsFilterItems,
          pageIndex: internalPageIndex || pageIndex,
          pageSize: internalPageSize || pageSize,
          offset: internalPageIndex * internalPageSize,
          sortBy: id,
          sortDirection: desc ? 'desc' : 'asc',
        },
        dispatch
      );
    },
    [pageSize, pageIndex, marketplaceContractsFilterItems] // eslint-disable-line
  );

  const controlledPaginationProps = useMemo(() => {
    return {
      loading: loadingMarketplaceContractsTableData,
      pageCount,
      fetchData,
      pageIndex,
    };
  }, [pageIndex, pageCount, loadingMarketplaceContractsTableData, fetchData]);

  // TODO: Write a date wrapper, so we can use the same component for range date picker

  return (
    <>
      <CS.PageTitleAndActions>
        <CS.JustifyToLeft>
          <CS.PageHeaderTitle>
            Marketplace Contracts
          </CS.PageHeaderTitle>
        </CS.JustifyToLeft>
      </CS.PageTitleAndActions>
      <CS.FilterSectionWrapper>
        <CS.FilterSection singleRow={false}>
          <div>
            <CS.FilterRow>
              <label htmlFor="startDate">
                <span>
                  Start Date
                </span>
                <CS.SpanWithDate useAsRange>
                  <span data-testid="textinput--start-date">
                    {`${displayFormattedDate(startDateFrom)}
							 			- ${displayFormattedDate(startDateTo)}`}
                  </span>
                  <CS.SpanWithDateButtonsWrapper>
                    {startDateFrom || startDateTo ? (
                      <Button
                        data-testid="button--clear-start-date"
                        variant="secondary"
                        size="small"
                        onClick={() => {
                          dispatch({
                            type: 'clearMarketplaceContractsIndividualDate',
                            payload: {
                              startDateFrom: null,
                              startDateTo: null,
                            },
                          });
                          setStartDateRange(undefined)
                        }}
                      >
                        <CancelIcon size="L" />
                      </Button>
                    ) : (
                      <Popover.Compose>
                        <Popover.Trigger>
                          <IconButton
                            data-testid="button--start-date"
                            variant="secondary"
                            icon={CalendarIcon}
                          />
                        </Popover.Trigger>
                        <Popover.Content.Compose placement='right'>
                          <Popover.Content.Body.Compose style={{ display: 'flex', width: '100%' }}>
                            <RangeDatePicker
                              selectedRange={startDateRange}
                              onRangeSelect={(selectedRange) => {
                                setStartDateRange(selectedRange);
                                handleDayClick({
                                  dispatch,
                                  newDate: selectedRange,
                                  key: 'startDate',
                                  dispatchType: 'setMarketplaceContractsFilterItems',
                                });
                              }}
                            />
                          </Popover.Content.Body.Compose>
                        </Popover.Content.Compose>
                      </Popover.Compose>
                    )}
                  </CS.SpanWithDateButtonsWrapper>
                </CS.SpanWithDate>
              </label>
              <label htmlFor="contractId">
                Contract ID
                <Input
                  autoComplete="new-contract-id"
                  data-testid="textinput--contract-id"
                  placeholder="All"
                  type="number"
                  name="contractId"
                  id="contractId"
                  value={contractId}
                  width="17rem"
                  onChange={(event) => updateStateOnInputTypeWrapper(event)}
                />
              </label>
              <label htmlFor="customerName">
                Customer name
                <Input
                  autoComplete="new-customer-name"
                  data-testid="textinput--customer-name"
                  width="17rem"
                  placeholder="All"
                  type="text"
                  name="customerName"
                  id="customerName"
                  value={customerName}
                  onChange={(event) => updateStateOnInputTypeWrapper(event)}
                />
              </label>
            </CS.FilterRow>
            <CS.FilterRow>
              <label htmlFor="endDate">
                <span>
                  End Date
                </span>
                <CS.SpanWithDate useAsRange>
                  <span data-testid="textinput--end-date">
                    {`${displayFormattedDate(endDateFrom)}
									- ${displayFormattedDate(endDateTo)}`}
                  </span>
                  <CS.SpanWithDateButtonsWrapper>
                    {endDateFrom || endDateTo ? (
                      <Button
                        data-testid="button--clear-end-date"
                        variant="secondary"
                        size="small"
                        onClick={() => {
                          dispatch({
                            type: 'clearMarketplaceContractsIndividualDate',
                            payload: {
                              endDateFrom: null,
                              endDateTo: null,
                            },
                          });
                          setEndDateRange(undefined)
                        }}
                      >
                        <CancelIcon size="L" />
                      </Button>
                    ) : (
                      <Popover.Compose>
                        <Popover.Trigger>
                          <IconButton
                            data-testid="button--end-date"
                            variant="secondary"
                            icon={CalendarIcon}
                          />
                        </Popover.Trigger>
                        <Popover.Content.Compose placement='right'>
                          <Popover.Content.Body.Compose style={{ display: 'flex', width: '100%' }}>
                            <RangeDatePicker
                              selectedRange={endDateRange}
                              onRangeSelect={(selectedRange) => {
                                setEndDateRange(selectedRange);
                                handleDayClick({
                                  dispatch,
                                  newDate: selectedRange,
                                  key: 'endDate',
                                  dispatchType: 'setMarketplaceContractsFilterItems',
                                });
                              }}
                            />
                          </Popover.Content.Body.Compose>
                        </Popover.Content.Compose>
                      </Popover.Compose>
                    )}
                  </CS.SpanWithDateButtonsWrapper>
                </CS.SpanWithDate>
              </label>
              <label htmlFor="smAccountId">
                SM Account ID
                <Input
                  autoComplete="new-sm-account-id"
                  data-testid="textinput--sm-account-id"
                  width="17rem"
                  placeholder="All"
                  type="number"
                  name="smAccountId"
                  id="smAccountId"
                  value={smAccountId}
                  onChange={(event) => updateStateOnInputTypeWrapper(event)}
                />
              </label>
              <label htmlFor="smAccountName">
                SM account name
                <Input
                  autoComplete="new-sm-account-name"
                  data-testid="textinput--sm-account-name"
                  width="17rem"
                  placeholder="All"
                  type="text"
                  name="smAccountName"
                  id="smAccountName"
                  value={smAccountName}
                  onChange={(event) => updateStateOnInputTypeWrapper(event)}
                />
              </label>
              <label htmlFor="status">
                {marketplaceContractsLoadPocConfig ? (
                  <CS.StatusLoading>
                    Loading...
                  </CS.StatusLoading>
                ) : (
                  <>
                    Status
                    <S.StyledMarketplaceContractsDropdownMenu
                      items={marketplaceContractsStatuses}
                      dataTestIdSuffix="status"
                      selectedItem={status}
                      renderItem={(item) => (<div>
                        {item.label}
                      </div>)}
                      onSelectedItemChange={(item) => {
                        dispatch({
                          type: 'setMarketplaceContractsFilterItems',
                          payload: { status: item.selectedItem },
                        });
                      }}
                    />
                  </>
                )}
              </label>
            </CS.FilterRow>
          </div>
          <CS.ClearAndApplyOneRowWrap>
            <Button
              data-testid="button--clear-all"
              variant="link"
              onClick={() => {
                dispatch({
                  type: 'resetMarketplaceContractsPageFilterItems',
                });
              }}
            >
              <ResetIcon size="L" />
              <span>
                Clear All
              </span>
            </Button>
            <Button
              variant="secondary"
              data-testid="button--apply-filters"
              onClick={() => {
                getMarketplaceContractsTableData(marketplaceContractsFilterItems, dispatch);
              }}
            >
              Apply filters
            </Button>
          </CS.ClearAndApplyOneRowWrap>
        </CS.FilterSection>
      </CS.FilterSectionWrapper>
      <CS.TableWrapper>
        <Table
          columns={marketplaceContractsColumns}
          data={marketplaceContractsTableData}
          manualSortBy
          skipReset
          initialPageSize={pageSize}
          initialSortBy={TABLE_DEFAULT_SORT}
          controlledPaginationProps={controlledPaginationProps}
          onRowClick={(props) => {
            const {
              values: { contractId: rowContractId },
            } = props;

            history.push(`/marketplaceContracts/${rowContractId}/view`);
          }}
        />
      </CS.TableWrapper>
    </>
  );
};
export default MarketplaceContractsPage;
