import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EllipsisMenu, Tooltip } from '@redislabsdev/redislabs-ui-components';

import moment, { Moment } from 'moment';
import { POCS_WRITE } from '../../constants/permissionsConstants';
import * as CS from '../../styles/common.style';
import TableStatus from '../../components/TableStatus/TableStatus';
import { displayFormattedDate } from '../contractsPage/contractsPage.utils';
import { StoreInterface } from '../../interfaces/storeInterfaces';
import { EllipsisItem } from './PocPage.types';

/*
 The date picker should allow the minimal (earlier) between the following:
 1. Allow extending POC End Time from 1-7 days from existing POC End Time
 2. Allow extending POC End Time no more than 14 days from today (the date on which the user is trying to extend)
 */
export const MAX_EXTENSION = {
  rule1: 7,
  rule2: 13, // rule2 is 13 days but count starts from current day
};

export const PocsColumnNames = [
  {
    header: 'POC ID',
    accessor: 'pocId',
    width: 10,
    disableFilters: true,
  },
  {
    header: 'Start Date',
    accessor: 'startDate',
    disableFilters: true,
    width: 10,
    Cell: ({ value }) => {
      return (<div>
          {displayFormattedDate(value)}
      </div>);
    },
  },
  {
    header: 'End Date',
    accessor: 'endDate',
    disableFilters: true,
    width: 10,
    Cell: ({ value }) => {
      return (<div>
          {displayFormattedDate(value)}
      </div>);
    },
  },
  {
    header: 'SM Account ID',
    accessor: 'accountId',
    width: 16,
    disableFilters: true,
  },
  {
    header: 'SM Account Name',
    accessor: 'accountName',
    width: 15,
    disableFilters: true,
  },
  {
    header: 'Activated By',
    accessor: 'boUserName',
    width: 25,
    disableFilters: true,
  },
  {
    header: 'Infra Cost',
    accessor: 'infrastructureCost',
    width: 10,
    disableFilters: true,
  },
  {
    header: 'POC Reason',
    accessor: 'comment',
    width: 30,
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ value }) => {
      return (
          <CS.TooltipWrapper>
              <Tooltip
                  tooltipContent={<CS.TooltipTextWithTooltip>
                      {value}
                  </CS.TooltipTextWithTooltip>}
                  placement="top"
                  trigger="hover"
                  textColor="blue2"
          >
                  <CS.TextWithTooltip>
                      {value}
                  </CS.TextWithTooltip>
              </Tooltip>
          </CS.TooltipWrapper>
      );
    },
  },
  {
    header: 'Status',
    accessor: 'status',
    disableFilters: true,
    width: 15,
    Cell: (props) => {
      const { value, row, data } = props;
      const dispatch = useDispatch();
      const permissions = useSelector((state: StoreInterface) => state.rootPage.permissions);
      const pocStatuses = useSelector((state: StoreInterface) => state.pocsPage.pocStatuses);
      const canWritePocs = permissions.includes(POCS_WRITE as never);
      const { endDate } = row.values;
      const items: EllipsisItem[] = [];

      const extendPocOption = {
        itemText: 'Extend POC',
        onClickAction: () => {
          dispatch({ type: 'resetPocData' });
          dispatch({ type: 'setShowExtendPocModal', payload: true });
          dispatch({ type: 'setExtendPocModalData', payload: data[row.index] });
        },
      };

      const stopPocOption = {
        itemText: 'Stop POC',
        onClickAction: () => {
          dispatch({ type: 'resetPocData' });
          dispatch({ type: 'setStopPocModalData', payload: data[row.index] });
        },
      };

      if (value === 'active' && canWritePocs) {
        if (moment.utc(endDate).isBefore(calculateExtendPocPeriod(endDate), 'day')) {
          items.push(extendPocOption);
        }

        items.push(stopPocOption);
      }

      return (
          <CS.StatusWrapper>
              <TableStatus
                  useFullWidth
                  customStatusWidth="100%"
                  status={value}
                  statuses={pocStatuses}
                  dataTestId="poc-status"
          />
              {items.length > 0 && <EllipsisMenu dataTestIdSuffix="poc-table-actions" items={items} />}
          </CS.StatusWrapper>
      );
    },
  },
];

export const calculateExtendPocPeriod = (endDate: Date | Moment | null): Moment | null => {
  if (!endDate) return null;

  const maxExtensionRule1 = moment.utc(endDate).startOf('day').add(MAX_EXTENSION.rule1, 'days');
  const maxExtensionRule2 = moment.utc().startOf('day').add(MAX_EXTENSION.rule2, 'days');

  return maxExtensionRule1.isBefore(maxExtensionRule2) ? maxExtensionRule1 : maxExtensionRule2;
};
