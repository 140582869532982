import { Sort } from './MaintenanceWindow.types';

export const getSortDirectionForColumn = (sort: Sort | null, accessor: string) => {
  let sortDirection = 'asc';
  if (sort?.sortDirection === 'asc' && accessor === sort?.sortBy) {
    sortDirection = 'desc';
  }

  return sortDirection;
};
