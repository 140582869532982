const prodValues = {
  accounts: 'accounts',
  contracts: 'contracts',
  bousers: 'bousers',
  subscriptions: 'subscriptions',
  accountsMarketplaces: 'accountsMarketplaces',
  bdbs: 'bdbs',
  poc: 'contracts/poc',
  coupon: 'coupons',
  audit: 'audit/events',
  maintenanceWindow: 'maintenanceWindow',
};

const defaultBaseUrl = {
  production: prodValues,
  test: prodValues,
  development: {
    accounts: 'http://localhost:8083',
    contracts: 'http://localhost:8081',
    bousers: 'http://localhost:8084',
    subscriptions: 'http://localhost:8086',
    bdbs: 'http://localhost:8087',
    accountsMarketplaces: 'http://localhost:8088',
    poc: 'http://localhost:8081/poc', // same as conctracts
    coupon: 'http://localhost:8085',
    audit: 'http://localhost:8082',
    maintenanceWindow: 'http://localhost:8091',
  },
};

export default defaultBaseUrl;
