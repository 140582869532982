import React from 'react';
import { Link } from 'react-router-dom';

// @ts-ignore
import { ReactComponent as RedisIcon } from '../../styles/images/redis-io-logo-icon.svg';
import * as S from './LogInPage.styles';

const LogInPage: React.FC = () => {
  return (
      <S.LoginPageWrapper>
          <div>
              <S.LogoWrapper>
                  <RedisIcon />
              </S.LogoWrapper>

              <S.Text>
                  NEW BACKOFFICE
              </S.Text>
          </div>

          <S.LinkWrapper>
              <Link to="/home">
                  Go to LogIn page
              </Link>
          </S.LinkWrapper>
      </S.LoginPageWrapper>
  );
};

export default LogInPage;
