import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table } from '@redislabsdev/redislabs-ui-components';
import { Input, RangeDatePicker, DateRange, IconButton, Popover } from '@redislabsdev/redis-ui-components';
import { CalendarIcon, ResetIcon } from '@redislabsdev/redis-ui-icons';
import moment from 'moment';

import * as CS from '../../styles/common.style';
import { displayFormattedDate } from '../contractsPage/contractsPage.utils';
import { updateStateOnInputType } from '../../utils/common.utils';

import { StoreInterface } from '../../interfaces/storeInterfaces';
import { auditLogsColumnNames } from './AuditLogsPage.utils';
import { getAuditLogsTableData } from './AuditLogsPage.api';

const TABLE_DEFAULT_SORT = [{ id: 'timestamp', desc: true }];

const AuditLogsPage: React.FC = () => {
  const dispatch = useDispatch();

  const loadingAuditLogsTableData = useSelector(
    (state: StoreInterface) => state.auditLogsPage.loadingAuditLogsTableData
  );
  const auditLogsTableData = useSelector(
    (state: StoreInterface) => state.auditLogsPage.auditLogsTableData
  );
  const auditLogsFilterItems = useSelector(
    (state: StoreInterface) => state.auditLogsPage.auditLogsFilterItems
  );

  const {
    startDateFrom,
    startDateTo,
    queryText,
    pageSize,
    pageCount,
    pageIndex,
  } = auditLogsFilterItems;

  const [startDateRange, setStartDateRange] = useState<DateRange>();

  const fetchData = useCallback(
    ({ pageIndex: internalPageIndex, pageSize: internalPageSize, sortBy: internalSortBy }) => {
      const { id, desc } = internalSortBy[0];

      getAuditLogsTableData(
        {
          ...auditLogsFilterItems,
          pageIndex: internalPageIndex || pageIndex,
          pageSize: internalPageSize || pageSize,
          offset: internalPageIndex * internalPageSize,
          sortBy: id,
          sortDirection: desc ? 'desc' : 'asc',
        },
        dispatch
      );
    },
    [pageSize, pageIndex, auditLogsFilterItems] // eslint-disable-line
  );

  const controlledPaginationProps = useMemo(() => {
    return {
      loading: loadingAuditLogsTableData,
      pageCount,
      fetchData,
      pageIndex,
    };
  }, [pageIndex, pageCount, loadingAuditLogsTableData, fetchData]);

  return (
    <>
      <CS.PageTitleAndActions>
        <CS.JustifyToLeft>
          <CS.PageHeaderTitle>
            Audit Logs
          </CS.PageHeaderTitle>
        </CS.JustifyToLeft>
      </CS.PageTitleAndActions>
      <CS.FilterSectionWrapper>
        <CS.FilterSection>
          <CS.FilterRow>
            <label htmlFor="startDate">
              <span>
                Start date *
              </span>
              <CS.SpanWithDate useAsRange>
                <span>
                  {`${displayFormattedDate(startDateFrom)}
							 			- ${displayFormattedDate(startDateTo)}`}
                </span>
                <CS.SpanWithDateButtonsWrapper>
                  <Popover.Compose>
                    <Popover.Trigger>
                      <IconButton
                        data-testid="button--start-date"
                        variant="secondary"
                        icon={CalendarIcon}
                      />
                    </Popover.Trigger>
                    <Popover.Content.Compose placement='right'>
                      <Popover.Content.Body.Compose style={{ display: 'flex', width: '100%' }}>
                        <RangeDatePicker
                          selectedRange={startDateRange}
                          onRangeSelect={(selectedRange) => {
                            setStartDateRange(selectedRange);
                            dispatch({
                              type: 'setAuditLogsFilterItems',
                              payload: {
                                startDateFrom: moment(selectedRange?.from).utcOffset(0, true),
                                startDateTo: moment(selectedRange?.to).utcOffset(0, true),
                              },
                            });
                          }}
                        />
                      </Popover.Content.Body.Compose>
                    </Popover.Content.Compose>
                  </Popover.Compose>
                </CS.SpanWithDateButtonsWrapper>
              </CS.SpanWithDate>
            </label>

            <label htmlFor="queryText">
              Search for Text
              <Input
                autoComplete="new-queryText"
                id="queryText"
                name="queryText"
                data-testid="textinput--queryText"
                type="text"
                value={queryText}
                placeholder="Search..."
                onChange={(event) =>
                  updateStateOnInputType({
                    eTarget: event.target,
                    action: 'setAuditLogsFilterItems',
                    dispatch,
                  })
                }
              />
            </label>
          </CS.FilterRow>
          <CS.ClearAndApplyOneRowWrap>
            <Button
              data-testid="button--clear-all"
              variant="link"
              onClick={() => {
                dispatch({
                  type: 'resetAuditLogsPageFilterItems',
                });
              }}
            >
              <ResetIcon size="M" />
              <span>
                Clear All
              </span>
            </Button>
            <Button
              variant="secondary"
              data-testid="button--apply-filters"
              onClick={() => {
                getAuditLogsTableData(auditLogsFilterItems, dispatch);
              }}
            >
              Apply filters
            </Button>
          </CS.ClearAndApplyOneRowWrap>
        </CS.FilterSection>
      </CS.FilterSectionWrapper>
      <CS.TableWrapper>
        <Table
          columns={auditLogsColumnNames}
          data={auditLogsTableData}
          manualSortBy
          skipReset
          initialPageSize={pageSize}
          initialSortBy={TABLE_DEFAULT_SORT}
          controlledPaginationProps={controlledPaginationProps}
        />
      </CS.TableWrapper>
    </>
  );
};

export default AuditLogsPage;
