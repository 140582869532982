import React from 'react';
import moment from 'moment';
import { SvgIcon, Tooltip } from '@redislabsdev/redislabs-ui-components';
import { InfoIcon } from '@redislabsdev/redis-ui-icons';
import { PROVIDERS } from '../../constants/accountsMarketplacesPageConstants';
import * as CS from '../../styles/common.style';
import * as S from './AccountsMarketplaces.styles';
import { AccountMarketplaceStatus } from './AccountsMarketplacesPage.types';

const getHeaderWithTooltip = (text: string, tooltipContent: string) => (
  <S.TableHeader>
    <S.TableHeaderText>{text}</S.TableHeaderText>
    <CS.TooltipWrapper>
      <Tooltip
        tooltipContent={tooltipContent}
        placement="top"
        maxWidth="27rem"
        trigger="hover"
        textColor="blue2"
      >
        <InfoIcon size="L" color="gray" />
      </Tooltip>
    </CS.TooltipWrapper>
  </S.TableHeader>
);

const getCellWithTooltip = (value: string | number) => (
  value ? (<CS.TooltipWrapper>
    <Tooltip
      tooltipContent={<CS.TooltipTextWithTooltip>
        {value}
      </CS.TooltipTextWithTooltip>}
      placement="top"
      padding='1rem'
      trigger="hover"
      textColor="blue2"
    >
      <CS.TextWithTooltip>
        {value}
      </CS.TextWithTooltip>
    </Tooltip>
  </CS.TooltipWrapper>) : 'N/A'
);

const accountsMarketplacesColumnNames = [
  {
    header: 'Provider',
    accessor: 'marketplace',
    disableFilters: true,
    width: 5,
    Cell: ({ value }) => {
      const iconName = PROVIDERS.find(({ code }) => code === value)?.icon;
      return (
        <SvgIcon width="20" height="20" name={iconName} />
      );
    },
  },
  {
    header: getHeaderWithTooltip("ID", "The marketplace subscription's internal ID in Redis Cloud (SM)"),
    accessor: 'accountMarketplaceId',
    disableFilters: true,
    width: 5,
  },
  {
    header: 'SM Account ID',
    accessor: 'smAccountId',
    disableFilters: true,
    width: 15,
    Cell: ({ value }) => <div>{value || 'N/A'}</div>,
  },
  {
    header: getHeaderWithTooltip("Order ID", `The marketplace subscription's order ID as it appears on the marketplace provider's "Manage orders" page`),
    accessor: 'entitlementId',
    disableFilters: true,
    width: 20,
    Cell: ({ value }) => getCellWithTooltip(value)
  },
  {
    header: getHeaderWithTooltip("Customer ref ID", "The marketplace provider's identifier for the marketplace subscription, also known as marketplace-id"),
    accessor: 'marketplaceId',
    disableFilters: true,
    width: 20,
    Cell: ({ value }) => getCellWithTooltip(value)
  },
  {
    header: 'Product',
    accessor: 'productCode',
    disableFilters: true,
    width: 15,
    Cell: ({ value }) => <CS.TextWithTooltip>{value || 'N/A'}</CS.TextWithTooltip>,
  },
  {
    header: 'Creation Time',
    accessor: 'creationDate',
    disableFilters: true,
    width: 15,
    Cell: ({ value }) => <div>{moment.utc(value).format('DD-MM-YYYY')}</div>,
  },
  {
    header: 'Last Changed',
    accessor: 'lastUpdateDate',
    disableFilters: true,
    width: 15,
    Cell: ({ value }) => <div>{moment.utc(value).format('DD-MM-YYYY')}</div>,
  },
  {
    header: 'Status',
    accessor: 'status',
    width: 20,
    disableFilters: true,
    disableSortBy: true,
  },
];

export const getAccountsMarketplacesColumns = (
  canWriteAccountsMarketplaces: boolean,
  accountsMarketplacesStatuses: AccountMarketplaceStatus[],
  dispatch
) => {
  return accountsMarketplacesColumnNames.map((column) => {
    if (column.accessor === 'status') {
      return {
        ...column,
        Cell: ({ value, row }) => {
          const status = accountsMarketplacesStatuses.find(({ code }) => code === value);
          return !canWriteAccountsMarketplaces ? <div>
            <S.StatusCircle status={value} />
            <span>{status?.label}</span>
          </div> : <div>
            <S.StatusDropdown
              dataTestIdSuffix="dropdown-status"
              items={accountsMarketplacesStatuses}
              selectedItem={status}
              renderItem={(item) => (<S.StatusOption>
                <S.StatusCircle status={item.code} />
                <span>{item.label}</span>
              </S.StatusOption>)}
              onSelectedItemChange={(item) => {
                dispatch({
                  type: 'setAccountMarketplaceStatusUpdateModalValues',
                  payload: {
                    showModal: true,
                    accountMarketplaceId: row.values.accountMarketplaceId,
                    status: item.selectedItem.code
                  },
                });
              }}
            />
          </div>
        }
      }
    }
    return column;
  });
};
