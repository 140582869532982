import React from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { Button, IconNamesEnum } from '@redislabsdev/redislabs-ui-components';
import { Input } from '@redislabsdev/redis-ui-components';
import { ShowIcon, EditIcon, Plus2Icon, DeleteIcon } from '@redislabsdev/redis-ui-icons';

import {
  AccName,
  SMAccountsNewLine,
  SpanDisplayFlexInline,
} from '../../../../styles/commonAccountDetails.style';
import { StoreInterface } from '../../../../interfaces/storeInterfaces';
import { setPaymentOption, setTemporaryPaymentOption } from './accountsSection.utils';
import * as AccountsSectionStyle from './AccountsSection.style';

const RenderAnAccount = (props) => {
  const {
    account: { accountId },
    account,
    view,
    getDetailsOnBlur,
    newWireTransferId,
    accDefaultPayOption,
    labelForWireTransfer,
  } = props;

  const dispatch = useDispatch();

  const tempSelectedItem = useSelector(
    (state: StoreInterface) =>
      state.contractsPage.contractInfoPickedAccounts[accountId]?.tempPaymentInfoId
  );

  const preparePaymentItem = (items) => {
    if (R.isEmpty(items) || R.isNil(items)) return [];

    return items.map((item) => {
      if (item?.method === 'wired-transfer') {
        return {
          id: item.paymentInfoId,
          icon: IconNamesEnum.BILLING,
          text: labelForWireTransfer,
        };
      }

      return {
        id: item.paymentInfoId,
        icon: IconNamesEnum[item.ccType.toUpperCase()],
        paymentInfo: {
          ccLast4Digits: item.ccLast4Digits,
          ccExpirationYear: item.ccExpirationYear,
          ccExpirationMonth: item.ccExpirationMonth,
        },
      };
    });
  };

  const getDefaultPaymentItem = (accID, newWTId, items) => {
    if (R.isEmpty(items) || R.isNil(items)) return [];

    if (newWTId) {
      // used to select the newly added wire transfer
      const item = R.find(R.propEq('paymentInfoId', newWTId), items);

      if (item) {
        return preparePaymentItem([item])[0];
      }
    }

    if (accID) {
      // used to select the default payment method
      const item = R.find(R.propEq('paymentInfoId', accID), items);

      return preparePaymentItem([item])[0];
    }

    // just selects the first payment method when there is no default one
    return preparePaymentItem([items[0]])[0];
  };

  const renderPaymentMethod = (acc) => {
    const accId = acc.accountId;
    const pInfos = acc.paymentInfos;
    const pInfo = acc.paymentInfo;
    const infoOrInfos = pInfo ? [pInfo] : pInfos;

    if (R.isEmpty(acc) || R.isNil(acc)) return null;

    const pInfoItems = preparePaymentItem(infoOrInfos);
    const defaultItem = getDefaultPaymentItem(accDefaultPayOption, newWireTransferId, infoOrInfos);

    setPaymentOption(accId, tempSelectedItem?.id || defaultItem.id, dispatch); // doing it here as the
    // function re-runs when we set a new ID when a new item is selected
    if (pInfoItems.length) {
      return (
          <AccountsSectionStyle.StyledDropdownMenu
              items={pInfoItems}
              onSelectedItemChange={(selectedObj) => {
            setTemporaryPaymentOption(accId, selectedObj.selectedItem, dispatch);
          }}
              selectedItem={tempSelectedItem || defaultItem}
              disabled={view}
              dataTestIdSuffix="paymentMethod"
        />
      );
    }

    return (
        <AccountsSectionStyle.StyledDropdownMenu
            items={[]}
            disabled
            placeholder="No payment methods available"
            dataTestIdSuffix="paymentMethod"
      />
    );
  };

  const includesWireTransfer = (arr) => {
    if (R.isEmpty(arr) || R.isNil(arr)) return false;

    return R.find(R.propEq('method', 'wired-transfer'), arr);
  };
  const areAddressesEqual = (obj1, obj2) => {
    return R.equals(obj1, obj2);
  };

  const buildWire = (acc) => {
    const accAdd = {
      ...acc.accountAddress,
      companyTaxId: acc.companyTaxId,
      vatCode: acc.vatCode,
    };
    const billAdd = includesWireTransfer(acc.paymentInfos)?.billingAddress;

    const mapBillAdd = {
      billAddress1: billAdd?.address1,
      billAddress2: billAdd?.address2,
      billCity: billAdd?.city,
      billZip: billAdd?.zip,
      billCountryCode: billAdd?.countryCode,
      billStateCode: billAdd?.stateCode,
    };

    return billAdd ? { ...accAdd, ...mapBillAdd } : accAdd;
  };

  const viewWireFunction = (acc) => {
    const accAdd = acc.accountAddress;
    const billAdd = includesWireTransfer(acc.paymentInfos)?.billingAddress;

    dispatch({
      type: 'wireTransferFormData',
      payload: buildWire(acc),
    });

    dispatch({
      type: 'setWireTransferOptions',
      payload: {
        wireForContractId: acc.accountId,
        viewType: 'viewWire',
        showModal: true,
        sameAccAddAsBill: areAddressesEqual(accAdd, billAdd),
      },
    });
  };

  const addWireFunction = (acc) => {
    dispatch({
      type: 'wireTransferFormData',
      payload: buildWire(acc),
    });

    dispatch({
      type: 'setWireTransferOptions',
      payload: {
        wireForContractId: acc.accountId,
        viewType: 'addWire',
        showModal: true,
        sameAccAddAsBill: true,
      },
    });
  };

  const editWireFunction = (acc) => {
    const accAdd = acc.accountAddress;
    const wireObject = includesWireTransfer(acc?.paymentInfos);
    const billAdd = wireObject?.billingAddress;
    const paymentInfoId = wireObject?.paymentInfoId;

    dispatch({
      type: 'wireTransferFormData',
      payload: buildWire(acc),
    });

    dispatch({
      type: 'setWireTransferOptions',
      payload: {
        wireForContractId: acc.accountId,
        viewType: 'editWire',
        showModal: true,
        sameAccAddAsBill: areAddressesEqual(accAdd, billAdd),
        paymentInfoId,
      },
    });
  };

  const renderMethodControlOptions = () => {
    const inclWT = includesWireTransfer(account.paymentInfos);

    if (inclWT) {
      return (
          <>
              <Button
                  data-testid="button--view-wire"
                  variant="link"
                  onClick={() => viewWireFunction(account)}
          >
                <ShowIcon size="M" />
                View wire transfer
              </Button>
              <Button
                  data-testid="button--edit-wire"
                  variant="link"
                  onClick={() => editWireFunction(account)}
          >
                <EditIcon size="M" />
                Edit wire transfer
              </Button>
          </>
      );
    }

    if (!view && !inclWT) {
      return (
          <Button
              data-testid="button--add-wire"
              variant="link"
              onClick={() => addWireFunction(account)}
        >
            <Plus2Icon size="M" />
            Add wire transfer
          </Button>
      );
    }

    return null;
  };

  if (!accountId) return null;

  return (
      <SpanDisplayFlexInline>
          <SMAccountsNewLine>
              {view || accountId !== 'placeholder' ? (
                  <AccName data-testid="accountId">
                      {accountId}
                  </AccName>
        ) : (
            <Input
                autoComplete="new-accountId"
                id={`${accountId || 'tempId'}`}
                name={`${accountId || 'tempId'}`}
                data-testid="textinput--accountId"
                type="number"
                defaultValue={accountId}
                onBlur={(event) => {
              const { value } = event.target;

              value && getDetailsOnBlur(event.target.value);
            }}
                onKeyPress={(event) => {
              if (event.key === 'Enter') {
                // @ts-ignore
                const { value } = event.target;
                // @ts-ignore
                value && getDetailsOnBlur(event.target.value);
              }
            }}
          />
        )}
          </SMAccountsNewLine>
          {account && accountId !== 'placeholder' ? (
              <>
                  <SMAccountsNewLine>
                      <AccName data-testid="accountName">
                          {account.accountName}
                      </AccName>
                  </SMAccountsNewLine>

                  <SMAccountsNewLine
                      view={view}
                      onClick={() => {
              // used to set the form's touch state
              dispatch({
                type: 'setFormTouchedState',
                payload: true,
              });
            }}
          >
                      {renderPaymentMethod(account)}
                      {!view && renderMethodControlOptions()}
                      {!view && (
                      <Button
                          size="small"
                          variant="link"
                          data-testid="button--remove-account"
                          onClick={() => {
                  dispatch({
                    type: 'removeAccountFromPayment',
                    payload: {
                      accountId,
                    },
                  });

                  // used to set the form's touch state
                  dispatch({
                    type: 'setFormTouchedState',
                    payload: true,
                  });
                }}
              >
                        <DeleteIcon size="L" />
                      </Button>
            )}
                  </SMAccountsNewLine>
              </>
      ) : null}
      </SpanDisplayFlexInline>
  );
};

export default RenderAnAccount;
