import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  SingleSelectorDropDown,
  Table,
} from '@redislabsdev/redislabs-ui-components';
import { Input } from '@redislabsdev/redis-ui-components';
import { ResetIcon } from '@redislabsdev/redis-ui-icons';
import * as CS from '../../styles/common.style';
import { updateStateOnInputType } from '../../utils/common.utils';
import { StoreInterface } from '../../interfaces/storeInterfaces';
import BdbsModal from './bdbsModal/BdbsModal';
import { cloudOptions, columnNames } from './ManualAa.utils';
import { getBdbsTableData, getTableData } from './ManualAa.api';

const ManualAaPage: React.FC = () => {
  const dispatch = useDispatch();
  const filterItems = useSelector((state: StoreInterface) => state.manualAaPage.filterItems);
  const manualAaTableData = useSelector(
    (state: StoreInterface) => state.manualAaPage.manualAaTableData
  );
  const loadingManualAaTableData = useSelector(
    (state: StoreInterface) => state.manualAaPage.loadingManualAaTableData
  );

  const { subscriptionId, accountId, accountName, cloudName, regionName } = filterItems;

  const openBdbsModal = (selectedSubscriptionId) => {
    dispatch({ type: 'setBdbsModalValues', payload: selectedSubscriptionId });
    getBdbsTableData(selectedSubscriptionId, dispatch);
  };

  const updateStateOnInputTypeWrapper = (event) =>
    updateStateOnInputType({
      eTarget: event.target,
      action: 'setManualAaFilterItems',
      dispatch,
    });

  return (
      <>
          <CS.PageTitleAndActions>
              <CS.JustifyToLeft>
                  <CS.PageHeaderTitle>
                      Manual A-A
                  </CS.PageHeaderTitle>
              </CS.JustifyToLeft>
          </CS.PageTitleAndActions>

          <CS.FilterSectionWrapper>
              <CS.FilterSection>
                  <CS.FilterRow>
                      <label htmlFor="subscriptionId">
                          Subscription ID
                          <Input
                              autoComplete="new-subscriptionId"
                              data-testid="textinput--subscriptionId"
                              width="16rem"
                              type="number"
                              name="subscriptionId"
                              id="subscriptionId"
                              value={subscriptionId}
                              placeholder="All"
                              onChange={(e) => updateStateOnInputTypeWrapper(e)}
              />
                      </label>

                      <label htmlFor="accountId">
                          Account ID
                          <Input
                              autoComplete="new-accountId"
                              data-testid="textinput--accountId"
                              width="16rem"
                              type="number"
                              name="accountId"
                              id="accountId"
                              value={accountId}
                              placeholder="All"
                              onChange={(e) => updateStateOnInputTypeWrapper(e)}
              />
                      </label>

                      <label htmlFor="accountName">
                          Account Name
                          <Input
                              autoComplete="new-accountName"
                              data-testid="textinput--accountName"
                              width="16rem"
                              type="text"
                              name="accountName"
                              id="accountName"
                              value={accountName}
                              placeholder="All"
                              onChange={(e) => updateStateOnInputTypeWrapper(e)}
              />
                      </label>

                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="cloudName">
                          Cloud
                          <SingleSelectorDropDown
                              data-role="dropdown-button"
                              optionList={cloudOptions}
                              defaultValue={{
                  id: 'all',
                  title: 'All',
                }}
                              width="16rem"
                              reset={cloudName === ''}
                              borderRadius
                              borderColor="gray1"
                              headerBorder
                              getSelectedOption={(item) => {
                  const itemId = item?.id === 'all' ? '' : item?.id;

                  dispatch({
                    type: 'setManualAaFilterItems',
                    payload: { cloudName: itemId },
                  });
                }}
              />
                      </label>

                      <label htmlFor="regionName">
                          Region
                          <Input
                              autoComplete="new-region"
                              data-testid="textinput--region"
                              width="16rem"
                              type="text"
                              name="regionName"
                              id="regionName"
                              value={regionName}
                              placeholder="All"
                              onChange={(e) => updateStateOnInputTypeWrapper(e)}
              />
                      </label>
                  </CS.FilterRow>
                  <CS.ClearAndApplyOneRowWrap>
                      <Button
                          data-testid="button--clear-all"
                          variant="link"
                          onClick={() => {
                dispatch({
                  type: 'resetManualAaFilterItems',
                });
              }}
            >
                        <ResetIcon size="M" />
                        <span>
                            Clear All
                        </span>
                      </Button>
                      <Button
                          variant="secondary"
                          data-testid="button--apply-filters"
                          onClick={() => {
                getTableData(filterItems, dispatch);
              }}
            >
                          Apply filters
                      </Button>
                  </CS.ClearAndApplyOneRowWrap>
              </CS.FilterSection>
          </CS.FilterSectionWrapper>

          <CS.TableWrapper>
              {loadingManualAaTableData ? (
                  <div>
                      Loading...
                  </div>
        ) : (
            <Table
                columns={columnNames}
                data={manualAaTableData}
                onRowClick={(props) => openBdbsModal(props.values.subscriptionId)}
          />
        )}
          </CS.TableWrapper>

          <BdbsModal />
      </>
  );
};

export default ManualAaPage;
