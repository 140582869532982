import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components/macro';

import {
  EllipsisMenu,
  Tooltip,
} from '@redislabsdev/redislabs-ui-components';
import { CouponsAddableIcon } from '@redislabsdev/redis-ui-icons'
import moment from 'moment';
import * as CS from '../../styles/common.style';
import TableStatus from '../../components/TableStatus/TableStatus';
import { displayFormattedDate } from '../contractsPage/contractsPage.utils';
import { StoreInterface } from '../../interfaces/storeInterfaces';
import { COUPONS_WRITE } from '../../constants/permissionsConstants';
import { couponPopUpLabels } from '../../constants/couponsPageConstants';
import { enableCoupon, exportCouponUsage, getCouponsTableData } from './CouponsPage.api';
import { CouponInterface, EllipsisItem } from './CouponsPage.types';
import * as S from './CouponsPage.styles';

const changeableStatuses = ['active', 'disabled'];

const displayExpirationPeriod = (value: number) => {
  if (!value) return 'No Expiration';
  return `${value} month${value > 1 ? 's' : ''}`;
};

const couponsColumnNames = [
  {
    header: 'Status',
    accessor: 'status',
    disableFilters: true,
    width: 1,
    Cell: ({ value }) => {
      const couponStatuses = useSelector(
        (state: StoreInterface) => state.couponsPage.couponStatuses
      );

      return (
          <CS.StatusWrapper>
              <Tooltip
                  tooltipContent={
                      <CS.TooltipTextWithTooltip>
                          {couponStatuses.filter((item) => item.code === value)[0]?.label ?? null}
                      </CS.TooltipTextWithTooltip>
            }
                  placement="top"
                  trigger="hover"
                  textColor="blue2"
          >
                  <TableStatus
                      useFullWidth
                      customStatusWidth="100%"
                      status={`coupon-${value}`}
                      statuses={couponStatuses}
                      hideLabel
                      dataTestId="coupon-status"
            />
              </Tooltip>
          </CS.StatusWrapper>
      );
    },
  },
  {
    header: 'Code',
    accessor: 'couponCode',
    width: 24,
    disableFilters: true,
    Cell: (props) => {
      const { value, data, row } = props;
      const theme = useTheme();
      const { description, addable, forCustomerRetention } = data[row.index];

      return (
          <S.CouponCodeTooltipWrapper>
              <Tooltip
                  tooltipContent={
                      <CS.TooltipTextWithTooltip>
                          <S.TooltipTitle data-testid="tooltip--coupon-code">
                              {value}
                              {forCustomerRetention && <span>
                                  , customer retention
                              </span>}
                          </S.TooltipTitle>
                          <S.TooltipContent data-testid="tooltip--coupon-description">
                              {description}
                          </S.TooltipContent>
                      </CS.TooltipTextWithTooltip>
            }
                  placement="top"
                  trigger="hover"
                  textColor="blue2"
          >
                  <S.ElipsisTextWithTooltip>
                      {value}
                  </S.ElipsisTextWithTooltip>
              </Tooltip>
              {addable && (
              <Tooltip
                  tooltipContent={
                      <CS.TooltipTextWithTooltip>
                          <S.TooltipContent data-testid="tooltip--coupon-addable">
                              Can be added to existing coupons
                          </S.TooltipContent>
                      </CS.TooltipTextWithTooltip>
              }
                  placement="top"
                  trigger="hover"
                  textColor="blue2"
            >
                <CouponsAddableIcon size="L" color={theme.colors.blue2} />
              </Tooltip>
          )}
          </S.CouponCodeTooltipWrapper>
      );
    },
  },
  {
    header: 'Date added',
    accessor: 'postedDate',
    disableFilters: true,
    width: 20,
    Cell: ({ value }) => {
      return (<div>
          {displayFormattedDate(value)}
      </div>);
    },
  },
  {
    header: 'Redeem by',
    accessor: 'expiryDate',
    disableFilters: true,
    width: 20,
    Cell: ({ value }) => {
      return (<div>
          {displayFormattedDate(value)}
      </div>);
    },
  },
  {
    header: 'Amount',
    accessor: 'amount',
    width: 20,
    Cell: ({ value }) => {
      return (<div>
          {`$ ${value || 0}`}
      </div>);
    },
    disableFilters: true,
  },
  {
    header: 'Times used',
    accessor: 'numOfAssociations',
    width: 20,
    disableFilters: true,
  },
  {
    header: 'Max uses',
    accessor: 'maxUses',
    width: 20,
    Cell: ({ value }) => {
      return (<div>
          {value || couponPopUpLabels.unlimited}
      </div>);
    },
    disableFilters: true,
  },
  {
    header: 'Created by',
    accessor: 'createdByUserEmail',
    width: 34,
    disableFilters: true,
    Cell: ({ value }) => {
      return (
          <CS.TooltipWrapper>
              <Tooltip
                  tooltipContent={<CS.TooltipTextWithTooltip>
                      {value}
                  </CS.TooltipTextWithTooltip>}
                  placement="top"
                  trigger="hover"
                  textColor="blue2"
          >
                  <CS.TextWithTooltip>
                      {value}
                  </CS.TextWithTooltip>
              </Tooltip>
          </CS.TooltipWrapper>
      );
    },
  },
  {
    header: 'Last modified by',
    accessor: 'lastModifiedByUserEmail',
    width: 40,
    disableFilters: true,
    Cell: (props) => {
      const { value, data, row } = props;
      const { status, numOfAssociations } = data[row.index];
      const dispatch = useDispatch();
      const permissions = useSelector((state: StoreInterface) => state.rootPage.permissions);
      const canWriteCoupons = permissions.includes(COUPONS_WRITE);
      const couponsFilterItems = useSelector(
        (state: StoreInterface) => state.couponsPage.couponsFilterItems
      );
      const onSuccessChangeStatus = () => {
        getCouponsTableData(couponsFilterItems, dispatch);
      };
      const items: EllipsisItem[] = [];
      const enableCouponOption: EllipsisItem = {
        itemText: status !== 'active' ? 'Enable' : 'Disable',
        onClickAction: () => {
          const { couponId } = data[row.index];

          enableCoupon(couponId, status !== 'active', onSuccessChangeStatus);
        },
      };
      const extendCouponOption: EllipsisItem = {
        itemText: 'Extend',
        onClickAction: () => {
          const { expiryDate } = data[row.index];
          const couponItem: CouponInterface = {
            ...data[row.index],
            expiryDate: moment.utc(expiryDate),
            newExpiryDate: moment.utc(expiryDate),
          };

          dispatch({ type: 'setShowCouponModal', payload: true });
          dispatch({ type: 'setCouponPopupData', payload: couponItem });
        },
      };

      const exportCouponOption: EllipsisItem = {
        itemText: 'Export',
        onClickAction: () => {
          const { couponId } = data[row.index];
          exportCouponUsage(couponId);
        },
      };

      if (canWriteCoupons) {
        if (changeableStatuses.includes(status)) {
          items.push(enableCouponOption);
        }
        items.push(extendCouponOption);
      }

      if (numOfAssociations) {
        items.push(exportCouponOption);
      }

      return (
          <S.LastModifiedContainer>
              <CS.TooltipWrapper>
                  <Tooltip
                      tooltipContent={<CS.TooltipTextWithTooltip>
                          {value}
                      </CS.TooltipTextWithTooltip>}
                      placement="top"
                      trigger="hover"
                      textColor="blue2"
            >
                      <CS.TextWithTooltip>
                          {value}
                      </CS.TextWithTooltip>
                  </Tooltip>
              </CS.TooltipWrapper>
              {items.length > 0 && (
              <EllipsisMenu dataTestIdSuffix="coupon-table-actions" items={items} />
          )}
          </S.LastModifiedContainer>
      );
    },
  },
];

export const getCouponsColumns = (couponsPhase2Enabled: boolean) => {
  // after the couponsPhase2Enabled flag is removed, add the below column
  // into the couponsColumnNames and export it as it is
  // and delete getCouponsColumns function
  const newColumns = [...couponsColumnNames];

  if (couponsPhase2Enabled) {
    const creditExpirationColumn = {
      header: 'Credit exp.',
      accessor: 'creditExpirationPeriod',
      disableFilters: true,
      width: 20,
      Cell: ({ value }) => {
        return (<div>
            {displayExpirationPeriod(value)}
        </div>);
      },
    };
    const expiryDateIndex = newColumns.findIndex((column) => column.accessor === 'expiryDate');

    newColumns.splice(expiryDateIndex + 1, 0, creditExpirationColumn);
  }

  return newColumns;
};
