import React, { useState } from 'react';
import { SingleSelectorDropDown } from '@redislabsdev/redislabs-ui-components';
import { Input } from '@redislabsdev/redis-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';

import * as S from '../ContractPage.style';
import { defaultStatesFromCountry } from '../accountsSection/accountsSection.utils';
import { updateStateOnInputType } from '../../../../utils/common.utils';
import { StoreInterface } from '../../../../interfaces/storeInterfaces';
import { SpanDisplayFlexInline } from '../../../../styles/commonAccountDetails.style';
import { formatCountriesAndStates, selectStatesFromCountry } from './wireTransferForm.utils';

const WireTransferForm = (props) => {
  // TODO: uncomment when wire-transfer bill address support will be reverted, and delete the
  //  row below
  // const { accountId, wireTransferFormData, wireTransferDataValidation, sameAccAddAsBill } = props;
  const { accountId, wireTransferFormData, wireTransferDataValidation } = props;

  const dispatch = useDispatch();
  const countriesStates = useSelector(
    (state: StoreInterface) => state.contractsPage.accountsConfiguration.countriesStates
  );
  const account = useSelector((state: StoreInterface) =>
    state.contractsPage.contractInfo.accWithDetails.get(accountId)
  );
  const viewType = useSelector(
    (state: StoreInterface) => state.contractPage.wireTransferOptions.viewType
  );

  const [statesFromCountry, setStatesFromCountry] = useState(defaultStatesFromCountry);
  const prepareCountriesAndStates = formatCountriesAndStates(countriesStates);

  const {
    address1,
    address2,
    city,
    zip,
    vatCode,
    companyTaxId,
    countryCode,
    stateCode,
    // billAddress1, // TODO: uncomment when wire-transfer bill address support will be reverted
    // billAddress2,
    // billCity,
    // billZip,
    // billStateCode,
    // billCountryCode,
  } = wireTransferFormData;

  const errorsMessages = {
    companyTaxId: 'Value must include 9 numbers',
  };

  const [initialDisableInputValues] = useState({
    address1,
    vatCode,
    companyTaxId,
    countryCode,
    // billCountryCode, // TODO: uncomment when wire-transfer bill address support will be reverted
  });

  const disableAccAddIfCompleted =
    viewType === 'viewWire' || (viewType !== 'viewWire' && initialDisableInputValues.address1);
  const disableVatIfCompleted =
    viewType === 'viewWire' || (viewType !== 'viewWire' && initialDisableInputValues.vatCode);
  const disableTaxIdIfCompleted =
    viewType === 'viewWire' || (viewType !== 'viewWire' && initialDisableInputValues.companyTaxId);

  const updateStateOnInputTypeWrapper = (event) =>
    updateStateOnInputType({
      eTarget: event.target,
      action: 'wireTransferFormData',
      dispatch,
    });

  return (
      <S.AddWireTransfer>
          <S.WireTransferHeader>
              <S.WireTransferHeaderItem>
                  <div>
                      SM account number
                  </div>
                  <div>
                      {accountId}
                  </div>
              </S.WireTransferHeaderItem>

              <S.WireTransferHeaderItem>
                  <div>
                      SM account name
                  </div>
                  <div>
                      {account?.accountName}
                  </div>
              </S.WireTransferHeaderItem>
          </S.WireTransferHeader>
          <S.DivDisplayInline>
              <S.SpanDisplayInline>
                  <S.WireTransferSectionTitle>
                      Account address
                  </S.WireTransferSectionTitle>
                  <SpanDisplayFlexInline>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="address1">
                          Address 1
                          <Input
                              autoComplete="new-address-1"
                              name="address1"
                              data-testid="textinput--address-1"
                              type="text"
                              width="100%"
                              disabled={disableAccAddIfCompleted}
                              value={address1}
                              onChange={(event) => updateStateOnInputTypeWrapper(event)}
              />
                      </label>
                  </SpanDisplayFlexInline>

                  <SpanDisplayFlexInline>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="address2">
                          Address 2
                          <Input
                              autoComplete="new-address-2"
                              name="address2"
                              data-testid="textinput--address-2"
                              type="text"
                              width="100%"
                              disabled={disableAccAddIfCompleted}
                              value={address2}
                              onChange={(event) => updateStateOnInputTypeWrapper(event)}
              />
                      </label>
                  </SpanDisplayFlexInline>

                  <SpanDisplayFlexInline>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="city">
                          City
                          <Input
                              autoComplete="new-city"
                              name="city"
                              data-testid="textinput--city"
                              type="text"
                              width="100%"
                              disabled={disableAccAddIfCompleted}
                              value={city}
                              onChange={(event) => updateStateOnInputTypeWrapper(event)}
              />
                      </label>

                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="zip">
                          Postal code
                          <Input
                              autoComplete="new-zip"
                              name="zip"
                              data-testid="textinput--zip"
                              type="text"
                              width="100%"
                              disabled={disableAccAddIfCompleted}
                              value={zip}
                              onChange={(event) => updateStateOnInputTypeWrapper(event)}
              />
                      </label>
                  </SpanDisplayFlexInline>

                  <SpanDisplayFlexInline>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="countryCode">
                          Country
                          <SingleSelectorDropDown
                              data-role="dropdown-button"
                              dataTestId="account-country"
                              optionList={prepareCountriesAndStates}
                              defaultValue={R.find(R.propEq('id', countryCode))(prepareCountriesAndStates)}
                              width="100%"
                              borderRadius
                              borderColor="gray1"
                              headerBorder
                              searchCapability
                              disabled={disableAccAddIfCompleted}
                              getSelectedOption={(item) => {
                  // @ts-ignore
                  const { id } = item;
                  const statesToUpdate = selectStatesFromCountry(id, countriesStates);

                  setStatesFromCountry((prevState) => ({
                    ...prevState,
                    stateCode: statesToUpdate,
                  }));

                  if (!disableVatIfCompleted) {
                    updateStateOnInputTypeWrapper({ target: { name: 'countryCode', value: id } });
                    updateStateOnInputTypeWrapper({
                      target: {
                        name: 'stateCode',
                        value: id !== countryCode ? '' : stateCode,
                      },
                    });
                    updateStateOnInputTypeWrapper({ target: { name: 'companyTaxId', value: '' } });
                    updateStateOnInputTypeWrapper({ target: { name: 'vatCode', value: '' } });
                  }
                }}
              />
                      </label>

                      {R.isEmpty(statesFromCountry.stateCode) ? null : (
              /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
                          <label htmlFor="stateCode">
                              State
                              <SingleSelectorDropDown
                                  data-role="dropdown-button"
                                  dataTestId="account-state"
                                  optionList={statesFromCountry.stateCode}
                                  defaultValue={
                    R.find(R.propEq('id', stateCode))(statesFromCountry.stateCode) || {
                      id: '',
                      title: '',
                    }
                  }
                                  width="100%"
                                  borderRadius
                                  borderColor="gray1"
                                  headerBorder
                                  searchCapability
                                  disabled={disableAccAddIfCompleted}
                                  reset={stateCode === ''}
                                  getSelectedOption={(item) => {
                    updateStateOnInputTypeWrapper({
                      target: { name: 'stateCode', value: item?.id },
                    });
                  }}
                />
                          </label>
            )}
                  </SpanDisplayFlexInline>

                  <SpanDisplayFlexInline>
                      {countryCode === 'il' ? null : (
              /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
                          <label htmlFor="vatCode">
                              VAT
                              <Input
                                  autoComplete="new-vatCode"
                                  name="vatCode"
                                  data-testid="textinput--vatCode"
                                  type="text"
                                  width="100%"
                                  disabled={disableVatIfCompleted}
                                  value={vatCode}
                                  onChange={(event) => updateStateOnInputTypeWrapper(event)}
                />
                          </label>
            )}

                      {countryCode !== 'il' ? null : (
              /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
                          <label htmlFor="companyTaxId">
                              Tax-id
                              <Input
                                  autoComplete="new-companyTaxId"
                                  name="companyTaxId"
                                  data-testid="textinput--companyTaxId"
                                  type="text"
                                  width="100%"
                                  disabled={disableTaxIdIfCompleted}
                                  minLength={9}
                                  maxLength={9}
                                  error={
                    !wireTransferDataValidation.companyTaxId
                      ? errorsMessages.companyTaxId
                      : undefined
                  }
                                  onBlur={({ target }) => {
                    dispatch({
                      type: 'wireTransferDataValidation',
                      payload: { companyTaxId: target.validity.valid },
                    });
                  }}
                                  value={companyTaxId}
                                  onChange={(event) => updateStateOnInputTypeWrapper(event)}
                />
                          </label>
            )}
                  </SpanDisplayFlexInline>
              </S.SpanDisplayInline>
              {/* TODO: uncomment when wire-transfer bill address support will be reverted */}
              {/*
         <S.SpanDisplayInline>
         <S.WireTransferSectionTitle>
         <S.SameAddressAsAccountWrapper>
         <span>Billing address</span>
         <ToggleButton
         onChange={(e) =>
         dispatch({
         type: 'setWireTransferOptions',
         payload: {
         sameAccAddAsBill: e.target.checked,
         },
         })
         }
         checked={sameAccAddAsBill}
         disabled={viewType === 'viewWire'}
         id="ToggleButton"
         data-testid="switch--account-address"
         />
         <span> Same address as Account</span>
         </S.SameAddressAsAccountWrapper>
         </S.WireTransferSectionTitle>
         {!sameAccAddAsBill && (
         <>
         <SpanDisplayFlexInline>
         <label htmlFor="billAddress1">
         Address 1
         <Input
         autoComplete="new-bill-address-1"
         name="billAddress1"
         data-testid="textinput--bill-address-1"
         type="text"
         width="100%"
         disabled={viewType === 'viewWire'}
         value={billAddress1}
         onChange={(event) => updateStateOnInputTypeWrapper(event)}
         />
         </label>
         </SpanDisplayFlexInline>

         <SpanDisplayFlexInline>
         <label htmlFor="billAddress2">
         Address 2
         <Input
         autoComplete="new-bill-address-2"
         name="billAddress2"
         data-testid="textinput--bill-address-2"
         type="text"
         width="100%"
         disabled={viewType === 'viewWire'}
         value={billAddress2}
         onChange={(event) => updateStateOnInputTypeWrapper(event)}
         />
         </label>
         </SpanDisplayFlexInline>

         <SpanDisplayFlexInline>
         <label htmlFor="billCity">
         City
         <Input
         autoComplete="new-bill-city"
         name="billCity"
         data-testid="textinput--bill-city"
         type="text"
         width="100%"
         disabled={viewType === 'viewWire'}
         value={billCity}
         onChange={(event) => updateStateOnInputTypeWrapper(event)}
         />
         </label>

         <label htmlFor="billZip">
         Zip
         <Input
         autoComplete="new-bill-zip"
         name="billZip"
         data-testid="textinput--bill-zip"
         type="text"
         width="100%"
         disabled={viewType === 'viewWire'}
         value={billZip}
         onChange={(event) => updateStateOnInputTypeWrapper(event)}
         />
         </label>
         </SpanDisplayFlexInline>

         <SpanDisplayFlexInline>
         <label htmlFor="billCountryCode">
         Country
         <SingleSelectorDropDown
         data-role="dropdown-button"
         dataTestId="bill-country"
         optionList={prepareCountriesAndStates}
         defaultValue={R.find(R.propEq('id', billCountryCode))(
         prepareCountriesAndStates
         )}
         width="100%"
         borderRadius
         searchCapability
         borderColor="gray1"
         headerBorder
         disabled={viewType === 'viewWire'}
         getSelectedOption={(item) => {
         // @ts-ignore
         const { id } = item;
         const statesToUpdate = selectStatesFromCountry(id, countriesStates);

         setStatesFromCountry((prevState) => ({
         ...prevState,
         billStateCode: statesToUpdate,
         }));

         updateStateOnInputTypeWrapper({
         target: { name: 'billCountryCode', value: id },
         });

         updateStateOnInputTypeWrapper({
         target: {
         name: 'billStateCode',
         value: id !== billCountryCode ? '' : billStateCode,
         },
         });
         }}
         />
         </label>

         {R.isEmpty(statesFromCountry.billStateCode) ? null : (
         <label htmlFor="billStateCode">
         State
         <SingleSelectorDropDown
         data-role="dropdown-button"
         dataTestId="bill-state"
         optionList={statesFromCountry.billStateCode}
         defaultValue={
         R.find(R.propEq('id', billStateCode))(statesFromCountry.billStateCode) || {
         id: '',
         title: '',
         }
         }
         width="100%"
         borderRadius
         borderColor="gray1"
         headerBorder
         searchCapability
         disabled={viewType === 'viewWire'}
         reset={billStateCode === ''}
         getSelectedOption={(item) => {
         updateStateOnInputTypeWrapper({
         target: { name: 'billStateCode', value: item?.id },
         });
         }}
         />
         </label>
         )}
         </SpanDisplayFlexInline>
         </>
         )}
         </S.SpanDisplayInline> */}
          </S.DivDisplayInline>
      </S.AddWireTransfer>
  );
};

export default WireTransferForm;
