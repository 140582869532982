import React, { useEffect, useState } from 'react';
import { Tooltip } from '@redislabsdev/redislabs-ui-components';
import { TablePagination } from '@redislabsdev/redislabs-ui-components/ui/components/Table';
import { ContractsIcon, SortDownIcon, SortUpIcon } from '@redislabsdev/redis-ui-icons';
import { Loader } from '@redislabsdev/redislabs-ui-components';
import { IconButton } from '@redislabsdev/redis-ui-components';
import { parseISO } from 'date-fns';
import classNames from 'classnames';
import { showToast } from '../../components/Toast/Toast';
import { grafanaClusterUrl } from '../../config';
import ProtectedComponent from '../../components/ProtectedComponent/ProtectedComponent';
import { MAINTENANCE_WINDOW_WRITE } from '../../constants/permissionsConstants';
import {
  Cluster,
  MaintenanceWindow,
  getMaintenanceWindowTableDataApiRequest,
  updateClusterNotes,
} from './MaintenanceWindowPage.api';
import { MaintenanceWindowClustersFilters, Sort, TableColumn } from './MaintenanceWindow.types';
import {
  NoBreakCell,
  RightAlignCell,
  StyledTable,
  StyledTextWithTooltip,
  TableContainer,
} from './Table.style';
import formatInTimeZone from './formatInTimeZone';
import { getSortDirectionForColumn } from './getSortDirectionForColumn';
import MaintenanceWindowClusterEditModal from './MaintenanceWindowClusterEditModal';

const buildLastMaintenanceTooltip = (record: Cluster) => {
  return (
    <ul>
      <li>
        Include system log: {record.lastMaintenanceActivity?.includeSystemLog ? 'true' : 'false'}
      </li>
      <li>Include email: {record.lastMaintenanceActivity?.includeEmail ? 'true' : 'false'}</li>
      <li>
        Update maintenance status:{' '}
        {record.lastMaintenanceActivity?.setMaintenanceMode ? 'true' : 'false'}
      </li>
      <li>Sendgrid Id: {record.lastMaintenanceActivity?.sendgridId}</li>
      <li>Notes: {record.lastMaintenanceActivity?.internalNotes}</li>
    </ul>
  );
};

const createColumns = (onEditClick: (row: Cluster) => void): TableColumn<Cluster>[] => [
  {
    header: 'RCP ID',
    accessor: 'rcpId',
    cellComponent: NoBreakCell,
    sortable: true,
  },
  {
    header: 'Mesh ID',
    accessor: 'meshId',
    cellComponent: NoBreakCell,
    sortable: true,
  },
  {
    header: 'Subscription ID',
    accessor: 'subscriptionId',
    cellComponent: NoBreakCell,
    sortable: true,
  },
  {
    header: 'Account ID',
    accessor: 'accountId',
    cellComponent: NoBreakCell,
    sortable: true,
  },
  {
    header: 'Cluster Version',
    accessor: 'swVersion',
    cellComponent: NoBreakCell,
    sortable: true,
  },
  {
    header: 'Opt In Version',
    accessor: 'optInVersion',
    cellComponent: NoBreakCell,
    sortable: true,
  },
  {
    header: 'Opt In Request Time',
    sortable: true,
    sortByKey: 'optInRequestTime',
    cellComponent: NoBreakCell,
    render: ({ optInRequestTime }: Cluster) => {
      if (!optInRequestTime) {
        return null;
      }

      const parsed = parseISO(optInRequestTime);
      return formatInTimeZone(parsed, 'd-MMM-y HH:mm:ss', 'UTC');
    },
  },
  {
    header: 'Provider',
    accessor: 'cloudProviderName',
    cellComponent: NoBreakCell,
    sortable: true,
  },
  {
    header: 'Maintenance Windows',
    render: ({ maintenanceWindows: windows }: Cluster) => {
      if (!windows.length) {
        return null;
      }
      const additionalWindows = windows.length > 1 ? `+${windows.length - 1}` : '';
      const cell = `${daysLookup[windows[0].days[0]]}… ${additionalWindows}`;
      return (
        <Tooltip tooltipContent={formatTooltip(windows)} textColor="#01112a">
          {cell}
        </Tooltip>
      );
    },
  },
  {
    header: 'Node Count',
    accessor: 'nodeCount',
    cellComponent: NoBreakCell,
  },
  {
    header: 'Region',
    accessor: 'regionName',
    cellComponent: NoBreakCell,
    sortable: true,
  },
  {
    header: 'Last Maintenance Time',
    sortByKey: 'lastMaintenanceTime',
    render: (row: Cluster) => {
      if (!row.lastMaintenanceActivity) {
        return null;
      }

      const parsed = parseISO(row.lastMaintenanceActivity.timestamp);
      return (
        <Tooltip tooltipContent={buildLastMaintenanceTooltip(row)} textColor={'black'}>
          <StyledTextWithTooltip>
            {formatInTimeZone(parsed, 'd-MMM-y HH:mm:ss', 'UTC')}
          </StyledTextWithTooltip>
        </Tooltip>
      );
    },
    cellComponent: NoBreakCell,
    sortable: true,
  },
  {
    header: 'Last Maintenance Activity',
    render: (row: Cluster) => {
      if (!row.lastMaintenanceActivity) {
        return null;
      }

      return (
        <Tooltip tooltipContent={buildLastMaintenanceTooltip(row)} textColor={'black'}>
          <StyledTextWithTooltip>
            {row.lastMaintenanceActivity.operations.join(', ')}
          </StyledTextWithTooltip>
        </Tooltip>
      );
    },
  },
  {
    header: 'Under Maintenance',
    render: (row: Cluster) => {
      if (!row.maintenanceMode) {
        return null;
      }

      return 'true';
    },
  },
  {
    header: 'Excluded',
    render: (row: Cluster) => <>{row.excludedOperations.join(', ')}</>,
  },
  {
    header: 'Off SM',
    accessor: 'offSm',
  },
  {
    header: 'Special Notes',
    accessor: 'clusterNotes',
  },
  {
    header: '',
    cellComponent: RightAlignCell,
    render: (row: Cluster) => (
      <ProtectedComponent requiredPermissions={MAINTENANCE_WINDOW_WRITE}>
        <IconButton variant="primary" icon={ContractsIcon} onClick={() => onEditClick(row)} />
      </ProtectedComponent>
    ),
  },
  ...(grafanaClusterUrl
    ? [
        {
          header: '',
          render: (row: Cluster) => {
            if (!row.clusterUrl) {
              return null;
            }

            const link = grafanaClusterUrl?.replace(
              '%cluster',
              row.clusterUrl.replace(/^https?:\/\//, '')
            );
            return (
              <a href={link} target="_blank">
                Grafana
              </a>
            );
          },
          cellComponent: NoBreakCell,
        },
      ]
    : []),
];

const daysLookup = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const mod = (x: number, n: number) => ((x % n) + n) % n;
const formatTooltip = (windows: MaintenanceWindow[]): JSX.Element => {
  const lines = windows.map((win, i) => {
    const days = win.days.map((d) => daysLookup[d]).join(', ');
    const hour = mod(win.from, 12) || 12; // if 0 then return 12 for 12 AM
    const line = (
      <li key={i}>{`${days} from ${hour} ${win.from < 12 ? 'AM' : 'PM'} for ${
        win.duration
      }h (UTC)`}</li>
    );
    return line;
  });
  const tooltip = <ul>{lines}</ul>;
  return tooltip;
};

const initialPageSize = 10;

export type MaintenanceWindowTableProps = {
  filters: MaintenanceWindowClustersFilters;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  sort: Sort | null;
  setSort: React.Dispatch<React.SetStateAction<null | Sort>>;
};

const MaintenanceWindowTable: React.FC<MaintenanceWindowTableProps> = ({
  filters,
  page,
  setPage,
  sort,
  setSort,
}) => {
  const [size, setSize] = useState(initialPageSize);
  const [loading, setLoading] = useState(true);

  const [clusters, setClusters] = useState<Cluster[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  // setting to number value opens edit modal
  const [clusterEditClusterId, setClusterEditClusterId] = useState<number | null>(null);

  const editingCluster = clusters.find((c) => c.clusterId === clusterEditClusterId);

  useEffect(() => {
    setLoading(true);
    getMaintenanceWindowTableDataApiRequest(page, size, filters, sort)
      .then((result) => {
        setClusters(result.data.records);
        setTotalRecords(result.data.totalRecords);
      })
      .catch(() => {
        showToast('Error fetching data');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page, size, filters, sort]);

  const columns = createColumns((cluster) => {
    setClusterEditClusterId(cluster.clusterId);
  });

  const pageCount = Math.ceil(totalRecords / size);

  const setSortForColumn = (accessor: string) => () => {
    setPage(0);
    setSort({ sortBy: accessor, sortDirection: getSortDirectionForColumn(sort, accessor) });
  };

  return (
    <div data-testid="mw-clusters">
      {loading && (
        <div data-testid="mw-fetching-indicator">
          <Loader />
        </div>
      )}
      <div hidden={loading}>
        <TableContainer>
          <StyledTable style={{ minWidth: '170rem' }}>
            <thead>
              <tr>
                {columns.map(({ header, accessor, sortable, sortByKey }, key) => {
                  const innerSortByKey = sortByKey || accessor;
                  const showDescendingArrow =
                    sort && sort.sortBy === innerSortByKey && sort.sortDirection === 'desc';
                  const arrow = showDescendingArrow ? (
                    <SortDownIcon size="M" />
                  ) : (
                    <SortUpIcon size="M" />
                  );
                  const classes = {
                    sortable,
                    sorted: sort && sort.sortBy === innerSortByKey,
                  };

                  return (
                    <th
                      key={key}
                      onClick={
                        sortable && innerSortByKey ? setSortForColumn(innerSortByKey) : undefined
                      }
                      className={classNames(classes)}
                    >
                      <div>
                        <div>{header}</div>
                        {sortable ? <div>{arrow}</div> : null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {!clusters.length && (
                <tr>
                  <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                    No results found.
                  </td>
                </tr>
              )}
              {clusters.map((row) => {
                const rowKey = `${row.clusterId}`;
                return (
                  <tr key={rowKey}>
                    {columns.map((col, key) => {
                      const value = col.render
                        ? col.render(row)
                        : col.accessor && row[col.accessor];

                      if (col.cellComponent) {
                        const CellComponent = col.cellComponent;
                        return <CellComponent key={`${rowKey}-${key}`}>{value}</CellComponent>;
                      }

                      return <td key={`${rowKey}-${key}`}>{value}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </StyledTable>
        </TableContainer>
        <TablePagination
          isPaginatedControlled={true}
          rows={clusters}
          canNextPage={page + 1 < pageCount}
          canPreviousPage={page > 0}
          pageIndex={page}
          pageCount={pageCount}
          pageSize={size}
          gotoPage={(pageIndex: number) => {
            setPage(pageIndex);
          }}
          nextPage={() => {
            setPage(page + 1);
          }}
          previousPage={() => {
            setPage(page - 1);
          }}
          setPageSize={(newSize: number) => {
            setSize(newSize);
          }}
        />
      </div>
      <MaintenanceWindowClusterEditModal
        cluster={editingCluster}
        onClose={() => {
          setClusterEditClusterId(null);
        }}
        onSubmit={async (clusterNotes) => {
          // programming error if this is not available
          if (!clusterEditClusterId) {
            throw new Error('Invalid cluster ID');
          }

          try {
            await updateClusterNotes(clusterEditClusterId, clusterNotes);
            setClusters((currentClusters) =>
              currentClusters.map((c) => {
                if (c.clusterId === clusterEditClusterId) {
                  return {
                    ...c,
                    clusterNotes,
                  };
                }
                return c;
              })
            );
            setClusterEditClusterId(null);
          } catch (error) {
            showToast('An error occurred while updating special notes.');
          }
        }}
      />
    </div>
  );
};

export default MaintenanceWindowTable;
