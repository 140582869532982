import styled from 'styled-components/macro';

export const TimeRangeRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div {
    width: 48%;
    padding-top: 1rem;
    border-top: 1px solid ${({ theme }) => theme.colors.lightGray2};
  }

  label {
    width: 100%;
  }
`;

export const DateButtonRow = styled.div`
  width: 100%;
  text-align: right;
  margin: 1.5rem 0 0;
`;

export const FilterSectionWrapper = styled.div`
  width: 100%;
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 0 16px #0000000f;
  border-radius: 0.8rem;
  margin-bottom: 1rem;
  padding: 1.3rem 1rem;
`;

export const FilterInputsWrapper = styled.div<{ $columnMaxLength?: string }>`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(160px, ${(props) => props.$columnMaxLength || '1fr'})
  );

  & > div {
    margin-right: 1rem;
  }

  & > div label {
    margin: 1.3rem 0;
  }

  & > div label:first-child {
    margin-top: 0;
  }
`;

export const FilterInputsGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SubmitGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  & > * {
    margin-left: 2rem;
  }
`;
