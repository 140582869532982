import React, { FC, SetStateAction, Dispatch } from 'react';
import { useFormikContext, Field, useField } from 'formik';
import { isArray } from 'lodash';
import styled from 'styled-components/macro';
import { Input, RadioGroup } from '@redislabsdev/redis-ui-components';
import { Button } from '@redislabsdev/redislabs-ui-components';
import { cardWithShadow } from '../../styles/commonAccountDetails.style';
import { EmailTemplateFormState } from './MaintenanceWindow.types';
import { FlexRadioGroup } from './Forms.styles';

const StyledFormRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;

  & > div {
    width: 50%;
  }
`;

const StyledFormColumn = styled.div`
  ${cardWithShadow};

  padding: 1em;
  margin: 1em 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const StyledError = styled.div`
  color: red;
`;

const required = (value) => {
  if (!value || (isArray(value) && !value.length)) {
    return 'Field must be filled out.';
  }

  return undefined;
};

const requiredSendgridId = (value) => {
  if (!value || value.search(/^[a-z0-9]-[a-z0-9]{32}$/i) === -1) {
    return 'Sendgrid Id does not match the correct format.';
  }

  return undefined;
};

type EmailTemplateFormProps = {
  setIsFormVisible: Dispatch<SetStateAction<boolean>>;
};

const EmailTemplateForm: FC<EmailTemplateFormProps> = ({ setIsFormVisible }) => {
  const {
    handleSubmit,
    values: formState,
    handleChange,
    handleBlur,
    errors,
    isSubmitting,
  } = useFormikContext<EmailTemplateFormState>();

  const [, , maintenanceTypeHelpers] = useField({ name: 'maintenance_type' });
  const [, , isUrgentHelpers] = useField({ name: 'is_urgent' });

  return (
    <form onSubmit={handleSubmit}>
      <StyledFormRow>
        <StyledFormColumn>
          <label htmlFor="friendly_name">
            Template Name
            <Field
              name="friendly_name"
              validate={required}
              render={() => (
                <>
                  <Input
                    type="text"
                    name="friendly_name"
                    id="friendly_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={formState.friendly_name}
                  />
                  {errors.friendly_name && <StyledError>{errors.friendly_name}</StyledError>}
                </>
              )}
            />
          </label>

          <label htmlFor="sendgrid_id">
            Sendgrid Id
            <Field
              name="sendgrid_id"
              validate={requiredSendgridId}
              render={() => (
                <>
                  <Input
                    type="text"
                    name="sendgrid_id"
                    id="sendgrid_id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={formState.sendgrid_id}
                  />
                  {errors.sendgrid_id && <StyledError>{errors.sendgrid_id}</StyledError>}
                </>
              )}
            />
          </label>

          <label htmlFor="maintenanceType">
            Activity Type
            <FlexRadioGroup
              initialValue={formState.maintenance_type}
              onChange={(value: string) => {
                maintenanceTypeHelpers.setValue(value);
                maintenanceTypeHelpers.setTouched(true, false);
              }}
            >
              <RadioGroup.Item.Compose value="start">
                <RadioGroup.Item.Indicator />
                <RadioGroup.Item.Label>Start</RadioGroup.Item.Label>
              </RadioGroup.Item.Compose>
              <RadioGroup.Item.Compose value="end">
                <RadioGroup.Item.Indicator />
                <RadioGroup.Item.Label>End</RadioGroup.Item.Label>
              </RadioGroup.Item.Compose>
            </FlexRadioGroup>
          </label>

          <label htmlFor="isUrgent">
            Urgency
            <FlexRadioGroup
              initialValue={formState.is_urgent ? 'true' : 'false'}
              onChange={(value: string) => {
                const booleanValue = value === 'true';
                isUrgentHelpers.setValue(booleanValue);
                isUrgentHelpers.setTouched(true, false);
              }}
            >
              <RadioGroup.Item.Compose value="false">
                <RadioGroup.Item.Indicator />
                <RadioGroup.Item.Label>Non-Urgent</RadioGroup.Item.Label>
              </RadioGroup.Item.Compose>
              <RadioGroup.Item.Compose value="true">
                <RadioGroup.Item.Indicator />
                <RadioGroup.Item.Label>Urgent</RadioGroup.Item.Label>
              </RadioGroup.Item.Compose>
            </FlexRadioGroup>
          </label>
        </StyledFormColumn>
      </StyledFormRow>
      <Button variant="primary" type="submit" disabled={isSubmitting}>
        Submit
      </Button>{' '}
      <Button
        variant="secondary"
        onClick={() => {
          setIsFormVisible(false);
        }}
        disabled={isSubmitting}
      >
        Cancel
      </Button>
    </form>
  );
};

export default EmailTemplateForm;
