import styled from 'styled-components/macro';

export const LoginPageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    justify-content: center;
    margin-top: 40vh;
    margin-right: 16rem; // takes sidebar in consideration + padding of container
  }
`;

export const LogoWrapper = styled.div`
  > svg {
    width: 10rem;
  }
`;

export const Text = styled.span`
  font-size: 5rem;
  padding-left: 2rem;
`;

export const LinkWrapper = styled.span`
  padding-left: 26.5rem;
  font-size: 1.7rem;
  text-decoration: none;
  text-align: center;
`;
