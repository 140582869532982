import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@redislabsdev/redislabs-ui-components';
import { Input, RangeDatePicker, DateRange, Popover, IconButton } from '@redislabsdev/redis-ui-components';
import { CalendarIcon, CancelIcon } from '@redislabsdev/redis-ui-icons';
import * as CS from '../../styles/common.style';
import { StoreInterface } from '../../interfaces/storeInterfaces';
import { updateStateOnInputType } from '../../utils/common.utils';
import { contractsFilterSectionLabels } from '../../constants/contractsPageConstants';
import * as S from './contractsPage.style';
import {
  displayFormattedDate,
  getAccountsConf,
  getTableData,
  handleDayClick,
} from './contractsPage.utils';
import ClearAllAndApplyButtons from './ClearAllAndApplyButtons';

const ContractsPageFilterSection: React.FC = () => {
  const dispatch = useDispatch();

  const filterItems = useSelector((state: StoreInterface) => state.contractsPage.filterItems);
  const cloudContractStatuses = useSelector(
    (state: StoreInterface) => state.contractsPage.accountsConfiguration.cloudContractStatuses
  );
  const loadingConfig = useSelector((state: StoreInterface) => state.contractsPage.loadingConfig);

  const [startDateRange, setStartDateRange] = useState<DateRange>();
  const [endDateRange, setEndDateRange] = useState<DateRange>();

  const {
    contractId,
    customerName,
    invoiceNumber,
    smAccountId,
    smAccountName,
    startDateFrom,
    startDateTo,
    endDateFrom,
    endDateTo,
    status,
  } = filterItems;

  const updateStateOnInputTypeWrapper = (event) =>
    updateStateOnInputType({
      eTarget: event.target,
      action: 'setFilterItems',
      dispatch,
      callback: () =>
        dispatch({
          type: 'setFormTouchedState',
          payload: true,
        }),
    });

  useEffect(() => {
    const callFun = async () => {
      if (cloudContractStatuses.length <= 1) {
        await getAccountsConf(dispatch);
      }
    };

    callFun();
  }, []); // eslint-disable-line

  return (
    <CS.FilterSectionWrapper>
      <CS.FilterSection singleRow={false}>
        <div>
          <CS.FilterRow>
            <label htmlFor="startDate">
              <span>
                {contractsFilterSectionLabels.startDate}
              </span>
              <CS.SpanWithDate useAsRange>
                <span data-testid="textinput--start-date">
                  {`${displayFormattedDate(startDateFrom)}
							 			- ${displayFormattedDate(startDateTo)}`}
                </span>
                <CS.SpanWithDateButtonsWrapper>
                  {startDateFrom || startDateTo ? (
                    <Button
                      data-testid="button--clear-start-date"
                      variant="secondary"
                      size="small"
                      onClick={() => {
                        dispatch({
                          type: 'clearIndividualDate',
                          payload: {
                            startDateFrom: null,
                            startDateTo: null,
                          },
                        });
                        setStartDateRange(undefined);
                      }}
                    >
                      <CancelIcon size="L" />
                    </Button>
                  ) : (
                    <Popover.Compose>
                      <Popover.Trigger>
                        <IconButton
                          data-testid="button--start-date"
                          variant="secondary"
                          icon={CalendarIcon}
                        />
                      </Popover.Trigger>
                      <Popover.Content.Compose placement='right'>
                        <Popover.Content.Body.Compose style={{ display: 'flex', width: '100%' }}>
                          <RangeDatePicker
                            selectedRange={startDateRange}
                            onRangeSelect={(selectedRange) => {
                              setStartDateRange(selectedRange);
                              handleDayClick({
                                dispatch,
                                newDate: selectedRange,
                                key: 'startDate',
                                dispatchType: 'setFilterItems',
                              });
                            }}
                          />
                        </Popover.Content.Body.Compose>
                      </Popover.Content.Compose>
                    </Popover.Compose>
                  )}
                </CS.SpanWithDateButtonsWrapper>
              </CS.SpanWithDate>
            </label>
            <label htmlFor="contractId">
              {contractsFilterSectionLabels.contractId}
              <Input
                autoComplete="new-contract-id"
                data-testid="textinput--contract-id"
                placeholder="All"
                type="number"
                name="contractId"
                id="contractId"
                value={contractId}
                width="16rem"
                onChange={(event) => updateStateOnInputTypeWrapper(event)}
              />
            </label>
            <label htmlFor="customerName">
              {contractsFilterSectionLabels.customerName}
              <Input
                autoComplete="new-customer-name"
                data-testid="textinput--customer-name"
                width="16rem"
                placeholder="All"
                type="text"
                name="customerName"
                id="customerName"
                value={customerName}
                onChange={(event) => updateStateOnInputTypeWrapper(event)}
              />
            </label>
            <label htmlFor="invoiceNumber">
              {contractsFilterSectionLabels.invoiceNumber}
              <Input
                autoComplete="new-invoice-number"
                data-testid="textinput--invoice-number"
                width="16rem"
                placeholder="All"
                type="text"
                name="invoiceNumber"
                id="invoiceNumber"
                value={invoiceNumber}
                onChange={(event) => updateStateOnInputTypeWrapper(event)}
              />
            </label>
          </CS.FilterRow>

          <CS.FilterRow>
            <label htmlFor="endDate">
              <span data-testid="textinput--end-date">
                {contractsFilterSectionLabels.endDate}
              </span>
              <CS.SpanWithDate useAsRange>
                <span>
                  {`${displayFormattedDate(endDateFrom)}
									- ${displayFormattedDate(endDateTo)}`}
                </span>
                <CS.SpanWithDateButtonsWrapper>
                  {endDateFrom || endDateTo ? (
                    <Button
                      data-testid="button--clear-end-date"
                      variant="secondary"
                      size="small"
                      onClick={() => {
                        dispatch({
                          type: 'clearIndividualDate',
                          payload: {
                            endDateFrom: null,
                            endDateTo: null,
                          },
                        });
                        setEndDateRange(undefined);
                      }}
                    >
                      <CancelIcon size="L" />
                    </Button>
                  ) : (
                    <Popover.Compose>
                      <Popover.Trigger>
                        <IconButton
                          data-testid="button--end-date"
                          variant="secondary"
                          icon={CalendarIcon}
                        />
                      </Popover.Trigger>
                      <Popover.Content.Compose placement='right'>
                        <Popover.Content.Body.Compose style={{ display: 'flex', width: '100%' }}>
                          <RangeDatePicker
                            selectedRange={endDateRange}
                            onRangeSelect={(selectedRange) => {
                              setEndDateRange(selectedRange);
                              handleDayClick({
                                dispatch,
                                newDate: selectedRange,
                                key: 'endDate',
                                dispatchType: 'setFilterItems',
                              });
                            }}
                          />
                        </Popover.Content.Body.Compose>
                      </Popover.Content.Compose>
                    </Popover.Compose>
                  )}
                </CS.SpanWithDateButtonsWrapper>
              </CS.SpanWithDate>
            </label>
            <label htmlFor="smAccountId">
              {contractsFilterSectionLabels.smAccountId}
              <Input
                autoComplete="new-sm-account-id"
                data-testid="textinput--sm-account-id"
                width="16rem"
                placeholder="All"
                type="number"
                name="smAccountId"
                id="smAccountId"
                value={smAccountId}
                onChange={(event) => updateStateOnInputTypeWrapper(event)}
              />
            </label>
            <label htmlFor="smAccountName">
              {contractsFilterSectionLabels.smAccountName}
              <Input
                autoComplete="new-sm-account-name"
                data-testid="textinput--sm-account-name"
                width="16rem"
                placeholder="All"
                type="text"
                name="smAccountName"
                id="smAccountName"
                value={smAccountName}
                onChange={(event) => updateStateOnInputTypeWrapper(event)}
              />
            </label>
            <label htmlFor="status">
              {loadingConfig ? (
                <CS.StatusLoading>
                  Loading...
                </CS.StatusLoading>
              ) : (
                <>
                  {contractsFilterSectionLabels.status}
                  <S.StatusDropdown
                    dataTestIdSuffix="dropdown-status"
                    items={cloudContractStatuses}
                    renderItem={(item) => (<div>
                      {item.label}
                    </div>)}
                    selectedItem={status}
                    onSelectedItemChange={(item) => {
                      dispatch({
                        type: 'setFilterItems',
                        payload: { status: item.selectedItem },
                      });
                    }}
                  />
                </>
              )}
            </label>
          </CS.FilterRow>
        </div>
        <ClearAllAndApplyButtons
          applyButtonCallback={() => getTableData(filterItems, dispatch)}
          clearAllCallback={() => dispatch({ type: 'resetPageFilterItems' })}
        />
      </CS.FilterSection>
    </CS.FilterSectionWrapper >
  );
};

export default ContractsPageFilterSection;
