import styled from 'styled-components/macro';

export * from '../contractsPage.style';

export const AddWireTransfer = styled.div`
  padding: 0 2rem;
`;

export const Link = styled.a`
  text-decoration: underline;
`;

export const DivDisplayInline = styled.div``;

export const SpanDisplayInline = styled.span`
  display: flex;
  flex-direction: column;
`;

export const WireTransferHeader = styled.div`
  display: flex;
  font-size: 1.5rem;
  color: #4d6992;
  justify-content: space-between;
  border-bottom: 1px #eaedf2 solid;
  padding-bottom: 1.5rem;

  & > div:first-child {
    text-align: left;
  }

  & > div:last-child {
    text-align: right;
  }
`;

export const WireTransferSectionTitle = styled.div`
  display: flex;
  margin-top: 2.5rem;
  font-size: 1.5rem;
  color: #01112a;
`;

export const SameAddressAsAccountWrapper = styled.div`
  & input#ToggleButton {
    margin: 0 1rem;
  }
`;

export const WireTransferHeaderItem = styled.div`
  & div:last-child {
    color: #01112a;
  }
`;

export const DeleteWarning = styled.div`
  font-size: 1.5rem;
`;

export const ColorWarning = styled.span`
  color: ${({ theme }) => theme.colors.yellow1};
  font-weight: bold;
`;

export const BoldDiv = styled.div`
  font-weight: bold;
`;
