import React, { useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
} from '@redislabsdev/redislabs-ui-components';
import { ShowIcon, Plus2Icon } from '@redislabsdev/redis-ui-icons';
import { ConfirmationDialog } from '@redislabsdev/redislabs-ui-components/ui/components/ConfirmationDialog';
import { v4 as uuidv4 } from 'uuid';
import * as R from 'ramda';
import WireTransferForm from '../wireTransferForm/WireTransferForm';
import { StoreInterface } from '../../../../interfaces/storeInterfaces';
import { getAccDetails } from '../../contractsPage.utils';
import ViewUsageReportPopup from '../viewUsageReport/ViewUsageReportPopup';
import {
  SectionTitle,
  SeparatorForTitle,
  SmAccountsSectionStyle,
  SMAccountsTitle,
  SpanDisplayFlexInline,
} from '../../../../styles/commonAccountDetails.style';
import { getShowViewUsageReportModal } from "../../../../selectors/ContractPageSelectors";
import {
  canSaveWire,
  getLabelForWireTransfer,
  saveFormAction,
  wireTransferTitle,
} from './accountsSection.utils';
import RenderAnAccount from './RenderAnAccount';

const SmAccountsSection: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const view = useRouteMatch('/contracts/:id/view') || false;
  const edit = useRouteMatch('/contracts/:id/edit') || false;
  const expand = useRouteMatch('/contracts/:id/expand') || false;

  // TODO: this has too many dependencies of the store, we may need to split this file
  const accounts = useSelector(
    (state: StoreInterface) => state.contractsPage.contractInfo.accounts
  );
  const accWithDetails = useSelector(
    (state: StoreInterface) => state.contractsPage.contractInfo.accWithDetails
  );
  const wireTransferFormData = useSelector(
    (state: StoreInterface) => state.contractPage.wireTransferFormData
  );
  const showModal = useSelector(
    (state: StoreInterface) => state.contractPage.wireTransferOptions.showModal
  );
  const viewType = useSelector(
    (state: StoreInterface) => state.contractPage.wireTransferOptions.viewType
  );
  const paymentInfoId = useSelector(
    (state: StoreInterface) => state.contractPage.wireTransferOptions.paymentInfoId
  );
  const wireForContractId = useSelector(
    (state: StoreInterface) => state.contractPage.wireTransferOptions.wireForContractId
  );
  const wireTransferDataValidation = useSelector(
    (state: StoreInterface) => state.contractPage.wireTransferDataValidation
  );
  const newWireTransferId = useSelector(
    (state: StoreInterface) => state.contractPage.newWireTransferId
  );

  const paymentInfoConfig = useSelector(
    (state: StoreInterface) => state.contractsPage.accountsConfiguration.paymentInfoConfig
  );

  const sameAccAddAsBill = useSelector(
    (state: StoreInterface) => state.contractPage.wireTransferOptions.sameAccAddAsBill
  );

  const contractInfo = useSelector(
    (state: StoreInterface) => state.contractsPage.contractInfo
  );

  const showViewUsageReportModal = useSelector(getShowViewUsageReportModal);

  const labelForWireTransfer = getLabelForWireTransfer(paymentInfoConfig);

  const itemsToRender = view ? accounts : accWithDetails;

  useEffect(() => {
    const callFun = () => {
      accounts.forEach(async (account) => {
        if (!R.has('paymentInfos', account) && account.accountId !== 'placeholder') {
          // TODO: investigate why this request is done two times for one account
          await getAccDetails(account.accountId, dispatch);
        }
      });
    };
    (edit || expand) && callFun();
  }, [location, accounts.size]); // eslint-disable-line

  const onCloseUsageReport = () => { dispatch({ type: 'showViewUsageReportModal', payload: false });}

  return (
      <>
          <SmAccountsSectionStyle>
              <SectionTitle>
                  SM Accounts
                  {view ? (
                      <Button
                          data-testid="button--view-usage-report"
                          variant="link"
                          onClick={() => dispatch({ type: 'showViewUsageReportModal', payload: true })}
            >
                        <ShowIcon size="M" />
                        <span>
                            View usage report
                        </span>
                      </Button>
          ) : (
              <Button
                  data-testid="button--add-account"
                  variant="primary"
                  onClick={() => {
                dispatch({
                  type: 'addAccountToPayment',
                  payload: ['placeholder', { accountId: 'placeholder' }], // used for adding a
                  // new account
                });

                // used to set the form's touch state
                dispatch({
                  type: 'setFormTouchedState',
                  payload: true,
                });
              }}
            >
                <Plus2Icon size="L" color="white" />
              </Button>
          )}
              </SectionTitle>
              <SpanDisplayFlexInline>
                  <SMAccountsTitle>
                      Account number
                  </SMAccountsTitle>
                  <SeparatorForTitle />
                  <SMAccountsTitle>
                      Account name
                  </SMAccountsTitle>
                  <SeparatorForTitle />
                  <SMAccountsTitle>
                      Payment method
                  </SMAccountsTitle>
              </SpanDisplayFlexInline>
              <>
                  {[...itemsToRender].map((acc) => {
            const account = acc[1]; // being a set, we need to take only the second elm
            const { accountId } = account;
            const accDefaultPayOption = accounts.get(accountId);

            return (
                <RenderAnAccount
                    key={uuidv4()}
                    account={account}
                    view={view}
                    newWireTransferId={newWireTransferId}
                    labelForWireTransfer={labelForWireTransfer}
                    accDefaultPayOption={accDefaultPayOption?.paymentInfo?.paymentInfoId}
                    getDetailsOnBlur={(accId) => {
                  const containsId = accounts && !accounts.has(accId);

                  containsId && getAccDetails(accId, dispatch);
                }}
              />
            );
          })}
              </>
          </SmAccountsSectionStyle>

          <ConfirmationDialog
              title={wireTransferTitle[viewType]}
              isOpen={showModal}
              cancelButtonLabel="Cancel"
              submitButtonLabel={viewType === 'viewWire' ? '' : 'Save Wire Transfer'}
              submitDisabled={
          !(
            canSaveWire(wireTransferFormData, sameAccAddAsBill) &&
            wireTransferDataValidation.companyTaxId
          )
        }
              handleModalCancel={() => {
          dispatch({ type: 'resetWireTransferOptions' });
          dispatch({ type: 'resetWireTransfer' });
        }}
              handleModalSubmit={() => {
          saveFormAction({
            accountId: wireForContractId,
            wireTransferFormData,
            sameAccAddAsBill,
            viewType,
            paymentInfoId,
            dispatch,
          });
        }}
      >
              <WireTransferForm
                  accountId={wireForContractId}
                  wireTransferFormData={wireTransferFormData}
                  wireTransferDataValidation={wireTransferDataValidation}
                  sameAccAddAsBill={sameAccAddAsBill}
        />
          </ConfirmationDialog>
          <ViewUsageReportPopup
              contractId={contractInfo.contractId}
              customerName={contractInfo.customerName}
              showViewUsageReportModal={showViewUsageReportModal}
              onClose={onCloseUsageReport}
      />
      </>
  );
};

export default SmAccountsSection;
