import { DateRange, IconButton, Popover, RangeDatePicker } from '@redislabsdev/redis-ui-components';
import { CalendarIcon, CancelIcon } from '@redislabsdev/redis-ui-icons';
import { Button, SingleSelectorDropDown, showToast } from '@redislabsdev/redislabs-ui-components';
import moment from 'moment';
import { useState } from 'react';
import * as CS from '../../styles/common.style';
import { MaintenanceWindowClustersFilters } from './MaintenanceWindow.types';
import { timeOptions } from './Select.options';
import { DateButtonRow, TimeRangeRow } from './TableFilters.style';

const defaultStartTime = '12:00 AM';
const defaultEndTime = '11:00 PM';

const displayFormattedDateTime = (displayedDate) =>
  moment.utc(displayedDate).format('DD/MM/YYYY hhA');

export type Props = {
  htmlFor: string;
  title: string;
  filters: MaintenanceWindowClustersFilters;
  handleFilterChange: (fieldName: string, value: unknown) => void;
  filterStartString: string;
  filterEndString: string;
  testIdSuffix: string;
};

export const MaintenanceWindowDateRangePicker = ({
  htmlFor,
  filters,
  handleFilterChange,
  filterStartString,
  filterEndString,
  title,
  testIdSuffix
}: Props) => {

  const [startTime, setStartTime] = useState<string>(defaultStartTime);
  const [endTime, setEndTime] = useState<string>(defaultEndTime);
  const [dateRange, setDateRange] = useState<DateRange | undefined>();
  const [showDatePopover, setShowDatePopover] = useState(false);

  return (
    <label htmlFor={htmlFor}>
      <span>{title}</span>
      <CS.SpanWithDate useAsRange>
        <span data-testid={'textinput--start-date' + testIdSuffix}>
          {filters[filterStartString]
            ? `${displayFormattedDateTime(filters[filterStartString])}
                          - ${displayFormattedDateTime(filters[filterEndString])}`
            : ''}
        </span>
        <CS.SpanWithDateButtonsWrapper>
          {filters[filterStartString] ? (
            <Button
              data-testid={"button--clear-start-date" + testIdSuffix}
              variant="secondary"
              size="small"
              onClick={() => {
                handleFilterChange(filterStartString, '');
                handleFilterChange(filterEndString, '');
              }}
            >
              <CancelIcon size="L" />
            </Button>
          ) : (
            <Popover.Compose visible={showDatePopover} onVisibilityChange={setShowDatePopover}>
              <Popover.Trigger>
                <IconButton
                  data-testid={"button--start-date" + testIdSuffix}
                  variant="secondary"
                  icon={CalendarIcon}
                />
              </Popover.Trigger>
              <Popover.Content.Compose placement="bottom">
                <Popover.Content.Body.Compose
                  style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                >
                  <RangeDatePicker
                    selectedRange={dateRange}
                    onRangeSelect={(selectedRange) => {
                      setDateRange(selectedRange);
                    }}
                  />
                  <TimeRangeRow>
                    <div>
                      <label htmlFor="fromTime">
                        From
                        <SingleSelectorDropDown
                          data-role="dropdown-button"
                          optionList={timeOptions}
                          defaultValue={{
                            id: defaultStartTime,
                            title: defaultStartTime,
                          }}
                          reset={startTime === defaultStartTime}
                          borderRadius
                          borderColor="gray1"
                          headerBorder
                          getSelectedOption={(item) => {
                            setStartTime(`${item?.id}`);
                          }}
                        />
                      </label>
                    </div>
                    <div>
                      <label htmlFor="toTime">
                        To
                        <SingleSelectorDropDown
                          data-role="dropdown-button"
                          optionList={timeOptions}
                          defaultValue={{
                            id: defaultEndTime,
                            title: defaultEndTime,
                          }}
                          reset={endTime === defaultEndTime}
                          borderRadius
                          borderColor="gray1"
                          headerBorder
                          getSelectedOption={(item) => {
                            setEndTime(`${item?.id}`);
                          }}
                        />
                      </label>
                    </div>
                  </TimeRangeRow>
                  <DateButtonRow>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setShowDatePopover(false);
                      }}
                    >
                      Cancel
                    </Button>{' '}
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setDateRange(undefined);
                        setStartTime(defaultStartTime);
                        setEndTime(defaultEndTime);
                      }}
                    >
                      Reset
                    </Button>{' '}
                    <Button
                      variant="primary"
                      onClick={() => {
                        const start = moment.utc(
                          moment(dateRange?.from).utcOffset(0, true).format('YYYY-MM-DD') +
                            ' ' +
                            startTime,
                          'YYYY-MM-DD hh:mm A'
                        );
                        const end = moment.utc(
                          moment(dateRange?.to || dateRange?.from)
                            .utcOffset(0, true)
                            .format('YYYY-MM-DD') +
                            ' ' +
                            endTime,
                          'YYYY-MM-DD hh:mm A'
                        );

                        if (start.unix() > end.unix()) {
                          showToast('Start Date is greater than End Date');
                        } else {
                          handleFilterChange(filterStartString, start.format('YYYY-MM-DD HH:mm:ss'));
                          handleFilterChange(filterEndString, end.format('YYYY-MM-DD HH:mm:ss'));

                          setShowDatePopover(false);
                        }
                      }}
                    >
                      Apply
                    </Button>
                  </DateButtonRow>
                </Popover.Content.Body.Compose>
              </Popover.Content.Compose>
            </Popover.Compose>
          )}
        </CS.SpanWithDateButtonsWrapper>
      </CS.SpanWithDate>
    </label>
  );
};
